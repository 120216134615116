import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { Clipboard, FileSystem, FileSystemStatus, Folder, Item, ItemType } from '../../../types/FileSystem'
import { useCallback } from 'react'
import { setLocal } from '../../../redux/action/local'
import props from '../../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import useMemberContext from '../../hooks/useContext/useMemberContext'
import { useNavigate } from 'react-router-dom'
import { FILE_SYSTEM_ROOT } from '../../../utility'

export type ChangeDirectory = (item: Folder) => void
export type AddItems = (items: Item[]) => void
export type RemoveItem = (item: Item) => void
export type MoveItem = (selectedItemUUID: string, targetPrefix: string) => Promise<boolean>
export type CopyItem = (selectedItemUUID: string, targetPrefix: string) => Promise<boolean>
export type SetSelectedItem = (item: Item) => void
export type ToggleFilePreview = (item?: Item) => void
export type UpdateClipboard = (clipboard: Clipboard) => void
export type Init = (activePrefix: string) => void
export type FetchFiles = (fileSystem: FileSystem) => void

type Response = {
  fileSystem: FileSystem
  changeDirectory: ChangeDirectory
  addItems: AddItems
  removeItem: RemoveItem
  moveItem: MoveItem
  copyItem: CopyItem
  setSelectedItem: SetSelectedItem
  toggleFilePreview: ToggleFilePreview
  updateClipboard: UpdateClipboard
  init: Init
  fetchFiles: FetchFiles
}

type ListResponse = {
  items: Item[]
  context: {
    projectUUID?: string
  }
}

const useFileSystem = (): Response => {
  const [memberContext] = useMemberContext()
  const listItemsWithContext = functional.use(Routes.FILE_SYSTEM_LIST_ITEMS_WITH_CONTEXT)
  const triggerItemMove = functional.use(Routes.FILE_SYSTEM_MOVE_ITEM)
  const triggerItemCopy = functional.use(Routes.FILE_SYSTEM_COPY_ITEM)
  const fileSystem: FileSystem = useSelector((s) => s[props.FILE_SYSTEM])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchFiles = useCallback(
    async (fileSystem: FileSystem) => {
      const itemsWithContext: ListResponse = await listItemsWithContext({
        prefix: fileSystem.activePrefix,
        companyUUID: fileSystem.activeCompanyUUID,
      })
      if (!!itemsWithContext) {
        const payload: FileSystem = {
          ...fileSystem,
          items: itemsWithContext.items,
          activeProjectUUID: itemsWithContext.context.projectUUID,
          activeSelectedItem: null,
          filePreviewVisibile: false,
          status: FileSystemStatus.READY,
        }
        dispatch(setLocal(props.FILE_SYSTEM, payload))
        navigate(`/dashboard/files/${encodeURIComponent(fileSystem.activePrefix)}`)
      }
    },
    [dispatch, listItemsWithContext, navigate],
  )

  const init: Init = (activePrefix: string) => {
    if (!!memberContext && memberContext.companies.length > 0) {
      const fileSystemPayload: FileSystem = {
        activeCompanyUUID: memberContext.companies[0].uuid,
        activePrefix,
        items: null,
        isRoot: activePrefix === FILE_SYSTEM_ROOT,
        status: FileSystemStatus.READY,
      }
      fetchFiles(fileSystemPayload)
    }
  }

  const changeDirectory: ChangeDirectory = (folder) => {
    const fs: FileSystem = {
      ...fileSystem,
      activePrefix: folder.key,
      isRoot: folder.key === FILE_SYSTEM_ROOT,
    }
    fetchFiles(fs)
  }

  const addItems: AddItems = (items) => {
    const updatedItems = [...fileSystem.items]
    for (const item of items) {
      const index = updatedItems.findIndex((i) => i.key === item.key)
      if (index === -1) {
        updatedItems.push(item)
      } else {
        updatedItems[index] = item
      }
    }
    const payload: FileSystem = { ...fileSystem, items: updatedItems }
    dispatch(setLocal(props.FILE_SYSTEM, payload))
  }

  const removeItem: RemoveItem = (item) => {
    const items = [...fileSystem.items.filter((i) => i.key !== item.key)]
    const payload: FileSystem = { ...fileSystem, items }
    dispatch(setLocal(props.FILE_SYSTEM, payload))
  }

  const setSelectedItem: SetSelectedItem = (item: Item) => {
    const payload: FileSystem = { ...fileSystem, activeSelectedItem: item }
    payload.activeSelectedItem = item
    payload.filePreviewVisibile = payload.filePreviewVisibile === true && item.type === ItemType.FILE
    dispatch(setLocal(props.FILE_SYSTEM, payload))
  }

  const toggleFilePreview: ToggleFilePreview = (item?: Item) => {
    if (!!item && item.type === ItemType.FILE) {
      const payload: FileSystem = { ...fileSystem, activeSelectedItem: item, filePreviewVisibile: true }
      dispatch(setLocal(props.FILE_SYSTEM, payload))
    } else {
      const payload: FileSystem = { ...fileSystem, activeSelectedItem: null, filePreviewVisibile: false }
      dispatch(setLocal(props.FILE_SYSTEM, payload))
    }
  }

  const moveItem: MoveItem = async (selectedItemUUID: string, targetPrefix: string) => {
    const status = await triggerItemMove({
      selectedItemUUID,
      targetPrefix,
    })

    if (status) {
      await fetchFiles({ ...fileSystem, clipboard: { ...fileSystem.clipboard, itemInTransit: null, transitMode: null } })
    }
    return !!status
  }

  const copyItem: CopyItem = async (selectedItemUUID: string, targetPrefix: string) => {
    const status = await triggerItemCopy({
      selectedItemUUID,
      targetPrefix,
    })
    if (status) {
      await fetchFiles(fileSystem)
    }
    return !!status
  }

  const updateClipboard: UpdateClipboard = (clipboard: Clipboard) => {
    const payload: FileSystem = { ...fileSystem, clipboard }
    dispatch(setLocal(props.FILE_SYSTEM, payload))
  }

  return {
    fileSystem,
    changeDirectory,
    addItems,
    removeItem,
    moveItem,
    copyItem,
    setSelectedItem,
    toggleFilePreview,
    updateClipboard,
    init,
    fetchFiles,
  }
}

export default useFileSystem

import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Budget, BudgetGroup } from '../../../../../types/Project'
import props from '../../../../../redux/props'
import Button from '../../../../UI/Button/Button'
import Modal from '../../../../UI/Modal/Modal'
import _ from 'lodash'
import Currency from '../../../../UI/Input/Currency'
import Group from './Group'
import { formatEUR } from '../../../../../utility'

type Props = {
  label?: string
  budget: Budget
  onChange: (value: Budget) => void
}

const BudgetManager: React.FC<Props> = ({ label, budget, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const set = (key: keyof Budget) => (value: any) => {
    const newBudget = _.cloneDeep(budget)
    newBudget[key] = value
    onChange(newBudget)
  }

  const upsertGroup = (group: BudgetGroup) => {
    const newBudget = _.cloneDeep(budget)
    const existingGroup = newBudget.groups.find((g) => g.uuid === group.uuid)
    if (existingGroup) {
      const index = newBudget.groups.indexOf(existingGroup)
      newBudget.groups[index] = group
    } else {
      newBudget.groups.push(group)
    }
    onChange(newBudget)
  }

  const removeGroup = (uuid: string) => () => {
    const newBudget = _.cloneDeep(budget)
    newBudget.groups = newBudget.groups.filter((g) => g.uuid !== uuid)
    onChange(newBudget)
  }

  const confirm = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (!budget) {
      onChange({
        groups: [],
        total: 0,
      })
    }
  }, [budget, onChange])

  if (!budget) return null
  return (
    <div>
      <label className="text-gray text-sm">{label || t.project.budget.label}</label>
      <div className="flex flex-col">
        <div>
          {t.project.budget.total}: <span>{formatEUR(budget.total)}</span>
        </div>
        <div>
          {t.project.budget.groups}: <span>{budget.groups.map((g) => g.name).join(', ')}</span>
        </div>
        <Button onClick={toggle} className="mt-1 text-left" text={t.generic.edit} small />
      </div>
      <Modal large title={label || t.project.budget.label} noForm onClose={toggle} show={isOpen} onConfirm={confirm}>
        <div className="flex flex-col gap-3">
          <Currency label={t.project.budget.total} value={budget.total} onChange={set('total')} />
          <div>
            <div className="text-gray text-sm">{t.project.budget.groups}</div>
            <div className="flex flex-col gap-2 max-h-[500px] overflow-x-hidden overflow-y-auto mb-2">
              {budget.groups.map((g, i) => (
                <Group key={g.uuid} initGroup={g} onChange={upsertGroup} remove={removeGroup(g.uuid)} />
              ))}
            </div>
            <Group onChange={upsertGroup} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BudgetManager

import { useSelector } from 'react-redux'
import Container from '../../../UI/Container/Container'
import Headline from '../../../UI/Headline/Headline'
import props from '../../../../redux/props'
import { useParams } from 'react-router-dom'
import useProject from './useProject'
import Loading from '../../../UI/Loading/Loading'
import FullHeightWrapper from '../../../UI/FullHeightWrapper/FullHeightWrapper'
import Input from '../../../UI/Input/Input'
import Form from '../../../UI/Form/Form'
import Button from '../../../UI/Button/Button'
import Toggle from '../../../UI/Toggle/Toggle'
import AreaManager from './AreaManager'
import Textarea from '../../../UI/Textarea/Textarea'
import ContactSelector from '../../../UI/ContactSelector/ContactSelector'
import MemberSelector from '../../../UI/MemberSelector/MemberSelector'
import BudgetManager from './BudgetManager/BudgetManager'
import HasPermission from '../../HasPermission/HasPermission'

const Update = () => {
  const { uuid } = useParams()
  const t = useSelector((s) => s[props.TRANSLATION])
  const [project, set, save] = useProject(uuid)

  return (
    <FullHeightWrapper>
      <Container>
        <div className="py-10">
          <Headline title={`${t.project.update.titlePrefix}${!!project && project.name}`} />
          <Loading loading={project} />
          {!!project && (
            <Form onSubmit={save}>
              <div className="flex flex-col gap-3">
                <Input disabled label={t.project.properties.name} value={project.name} onChange={set('name')} />
                <MemberSelector multiple label={t.project.update.selectMember} value={project.memberUUIDList} onChange={set('memberUUIDList')} />
                <ContactSelector label={t.project.update.selectContact} value={project.contactUUIDList} onChange={set('contactUUIDList')} />
                <AreaManager disableSearch value={project.areas} onChange={set('areas')} />
                <HasPermission permission="budgetManagement">
                  <BudgetManager budget={project.budget} onChange={set('budget')} />
                </HasPermission>
                <Input required type="email" label={t.project.properties.mail} value={project.mail} onChange={set('mail')} />
                <Textarea
                  rows={10}
                  required
                  label={t.project.properties.mailSignature}
                  value={project.mailSignature}
                  onChange={set('mailSignature')}
                />
                <Toggle
                  label={t.project.properties.isArchived}
                  hint={t.project.properties.isArchivedHint}
                  value={project.isArchived}
                  onChange={set('isArchived')}
                />
                <div className="flex justify-end">
                  <Button text={t.generic.save} type="submit" />
                </div>
              </div>
            </Form>
          )}
        </div>
      </Container>
    </FullHeightWrapper>
  )
}

export default Update

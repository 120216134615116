export enum FileSystemStatus {
  PENDING = 'PENDING',
  RESOLVE_DIRECTORY = 'RESOLVE_DIRECTORY',
  READY = 'READY',
}

export enum ItemType {
  FILE = 'FILE',
  FOLDER = 'FOLDER',
}

type SharedProps = {
  uuid?: string
  key: string
  projectUUID: string
  type: ItemType
  name: string
  prefix?: string
}

export type File = SharedProps & {
  uuid?: string
  eTag: string
  type: ItemType.FILE
  size: number
  createdDate: Date
  updatedDate: Date
}

export type Folder = SharedProps & {
  type: ItemType.FOLDER
  // size (number of included items in case of folder)
  size?: number
  // createdDate and updateDate is dynamic
  createdOn?: Date
  updatedOn?: Date
  // dynamic properties
  isProjectFolder?: boolean
}

export type Item = File | Folder

export enum ClipboardTransitMode {
  COPY = 'COPY',
  CUT = 'CUT',
}

export type Clipboard = {
  itemInTransit: Item
  transitMode: ClipboardTransitMode
}

export type FileSystem = {
  status: FileSystemStatus
  isRoot?: boolean
  activeCompanyUUID?: string
  activeProjectUUID?: string
  activePrefix?: string
  items?: Array<Item>
  activeSelectedItem?: Item
  filePreviewVisibile?: boolean
  clipboard?: Clipboard
}

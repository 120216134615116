import { ClipboardPaste, Copy, PenLine, SquareBottomDashedScissors, Trash } from 'lucide-react'
import { Item } from '../../../../../types/FileSystem'
import { ContextOption } from './ContextMenu'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useRemoveItem from './hooks/useRemoveItem'
import useClipboard from './hooks/useClipboard'

const useFolderOptions = (): ((item: Item) => ContextOption[]) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const clipboard = useClipboard()
  const removeItem = useRemoveItem()

  return (item: Item) => {
    return [
      {
        Icon: Copy,
        name: t.fileSystem.itemTable.contextMenu.folder.copy.cta,
        onClick: clipboard.copy(item),
        permission: 'moveFolders',
      },
      {
        Icon: SquareBottomDashedScissors,
        name: t.fileSystem.itemTable.contextMenu.folder.cut.cta,
        onClick: clipboard.cut(item),
        permission: 'moveFolders',
      },
      {
        Icon: ClipboardPaste,
        name: t.fileSystem.itemTable.contextMenu.folder.paste.cta,
        onClick: clipboard.paste(item),
        isActive: clipboard.hasItem,
      },
      {
        Icon: PenLine,
        name: t.fileSystem.itemTable.contextMenu.folder.rename.cta,
        rename: item,
        permission: 'moveFolders',
      },
      {
        Icon: Trash,
        name: t.fileSystem.itemTable.contextMenu.folder.remove.cta,
        confirm: t.fileSystem.itemTable.contextMenu.folder.remove.confirm(item),
        onClick: removeItem(item),
        permission: 'deleteFolders',
      },
    ]
  }
}

export default useFolderOptions

import { useState } from 'react'
import Button from '../../../UI/Button/Button'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Member } from '../../../../types/Member'
import Modal from '../../../UI/Modal/Modal'
import Input from '../../../UI/Input/Input'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import { Level } from '../../../../types/Auth'
import { PencilIcon } from '@heroicons/react/24/solid'
import Remove from './Remove/Remove'
import CompanyAssignment from './CompanyAssignment'

type Props = {
  member?: Member
  pullList: () => void
}

const Upsert: React.FC<Props> = ({ member, pullList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<Member>((member || {}) as Member)
  const memberFeature = crud.use(props.MEMBER)
  const toast = useToast()

  const toggle = () => setOpen(!open)

  const save = async () => {
    const result: Member = await memberFeature.upsert(data)
    if (!!result) {
      toast(t.settings.memberManagement.upsert.success, Type.SUCCESS)
      pullList()
      toggle()
    } else {
      toast(t.settings.memberManagement.upsert.error.upsert, Type.ERROR)
    }
  }

  const set = (key: keyof Member) => (value: any) => {
    if (key === 'firstname' && !member) {
      const identifier = `${value || ''}.${data.lastname || ''}`.toLowerCase()
      setData({ ...data, [key]: value, identifier })
    } else if (key === 'lastname' && !member) {
      const identifier = `${data.firstname || ''}.${value || ''}`.toLowerCase()
      setData({ ...data, [key]: value, identifier })
    } else {
      setData({ ...data, [key]: value })
    }
  }

  if (!data) return null
  return (
    <div>
      {data.uuid ? (
        <div className="flex flex-row items-center justify-between">
          <div>{`${data.firstname} ${data.lastname} - ${data.identifier}`}</div>
          <div className="flex flex-row gap-3">
            <Remove member={data} callback={pullList} />
            <PencilIcon className="h-5 fill-blue cursor-pointer" onClick={toggle} />
          </div>
        </div>
      ) : (
        <Button onClick={toggle} text={t.settings.memberManagement.upsert.cta} />
      )}
      <Modal onConfirm={save} show={open} onClose={toggle}>
        <div className="text-blue font-bold">
          {data?.uuid ? t.settings.memberManagement.upsert.titleUpdate : t.settings.memberManagement.upsert.titleCreate}
        </div>
        <div className="flex flex-col gap-3 mt-3">
          <Input required label placeholder={t.generic.firstname} value={data.firstname} onChange={set('firstname')} />
          <Input required label placeholder={t.generic.lastname} value={data.lastname} onChange={set('lastname')} />
          <Input required type="email" label placeholder={t.generic.mail} value={data.mail} onChange={set('mail')} />
          <Input required label placeholder={t.generic.identifier} value={data.identifier} onChange={set('identifier')} />
          <Input required={!data.uuid} type="password" label placeholder={t.generic.password} value={data.password} onChange={set('password')} />
          <Dropdown
            label={t.settings.accessManagement.level.title}
            required
            items={Object.keys(Level).map((l) => ({ label: t.settings.accessManagement.level[l], value: l }))}
            value={data.level}
            onChange={set('level')}
          />
          <CompanyAssignment value={data.companyUUIDList} onChange={set('companyUUIDList')} />
        </div>
      </Modal>
    </div>
  )
}

export default Upsert

import { useSelector } from 'react-redux'
import { useState } from 'react'
import useToast, { Type } from '../../../hooks/useToast'
import HasPermission from '../../HasPermission/HasPermission'
import Button from '../../../UI/Button/Button'
import Modal from '../../../UI/Modal/Modal'
import Input from '../../../UI/Input/Input'
import props from '../../../../redux/props'
import { Contact } from '../../../../types/Contact'
import useContacts from '../../../hooks/useContext/useContactEntries'
import { crud } from '@think-internet/zeus-frontend-package'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import { PencilIcon } from '@heroicons/react/24/solid'
import AnsprechpartnerManager from '../AnsprechpartnerManager/AnsprechpartnerManager'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import Textarea from '../../../UI/Textarea/Textarea'
import SuggestionInput from '../../../UI/SuggestionInput/SuggestionInput'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import useMemberContext from '../../../hooks/useContext/useMemberContext'
import { StatusType } from '../../../UI/Status/Status'

type Props = {
  initData?: Contact
}

const emptyContactEntry: Contact = {
  gewerk: '',
  firma: '',
  ansprechpartnerList: [],
  adresse: '',
  telefon: '',
  mobil: '',
  mail: '',
  website: '',
  projectUUIDList: [],
  bemerkung: '',
  isSensitive: false,
}

const Upsert: React.FC<Props> = ({ initData }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const [data, setData] = useState<Contact>(initData || emptyContactEntry)
  const [isOpen, setIsOpen] = useState(false)
  const [, , add, update, remove] = useContacts()
  const contactEntryFeature = crud.use(props.CONTACT_ENTRY)
  const [context] = useMemberContext()

  const save = async () => {
    const result: Contact = await contactEntryFeature.upsert(data)
    if (!!result) {
      if (!initData) {
        toast(t.contact.upsert.success.create, Type.SUCCESS)
        add(result)
        setData(emptyContactEntry)
      } else {
        toast(t.contact.upsert.success.update, Type.SUCCESS)
        update(result)
        setData(result)
      }
      toggle()
    } else {
      toast(t.contact.upsert.error.save, Type.ERROR)
    }
  }

  const removeEntry = async () => {
    const result = await contactEntryFeature.remove({ uuid: data.uuid })
    if (!!result) {
      toast(t.contact.upsert.success.remove, Type.SUCCESS)
      remove(data.uuid)
      toggle()
    } else {
      toast(t.contact.upsert.error.remove, Type.ERROR)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  const set = (key: keyof Contact) => (value: any) => setData({ ...data, [key]: value })

  if (!data || !context) return null
  return (
    <HasPermission permission="createContact">
      {!initData && <Button onClick={toggle} text={t.contact.upsert.cta} />}
      {!!initData && (
        <div onClick={toggle}>
          <Tooltip text={t.generic.edit}>
            <PencilIcon className="w-5 fill-blue" />
          </Tooltip>
        </div>
      )}
      <Modal
        large
        onClose={toggle}
        show={isOpen}
        onConfirm={save}
        onRemove={!!data.uuid && removeEntry}
        title={!!initData ? t.contact.upsert.title.update : t.contact.upsert.title.create}
      >
        <div className="flex gap-5">
          <div className="flex-1">
            <HasPermission permission="manageSensitiveContactData">
              <Checkbox label={t.contact.properties.isSensitive} checked={data.isSensitive} onChange={set('isSensitive')} />
            </HasPermission>
            <SuggestionInput
              feature={props.CONTACT_ENTRY}
              props={['gewerk']}
              label={t.contact.properties.gewerk}
              value={data.gewerk}
              onChange={set('gewerk')}
            />
            <Input label={t.contact.properties.firma} value={data.firma} onChange={set('firma')} />
            <Input label={t.contact.properties.kuerzel} value={data.kuerzel} onChange={set('kuerzel')} />
            <Input label={t.contact.properties.adresse} value={data.adresse} onChange={set('adresse')} />
            <Input label={t.contact.properties.telefon} value={data.telefon} onChange={set('telefon')} />
            <AnsprechpartnerManager value={data.ansprechpartnerList} onChange={set('ansprechpartnerList')} />
          </div>
          <div className="flex-1">
            <Input label={t.contact.properties.mobil} value={data.mobil} onChange={set('mobil')} />
            <Input label={t.contact.properties.mail} value={data.mail} onChange={set('mail')} />
            <Input label={t.contact.properties.website} value={data.website} onChange={set('website')} />
            <Textarea label={t.contact.properties.bemerkung} value={data.bemerkung} onChange={set('bemerkung')} />
            <Dropdown
              label={t.contact.properties.bewertung}
              required
              items={Object.keys(StatusType).map((b) => ({ label: t.contact.properties.bewertungItems[b], value: b }))}
              value={data.bewertung}
              onChange={set('bewertung')}
            />
          </div>
        </div>
      </Modal>
    </HasPermission>
  )
}

export default Upsert

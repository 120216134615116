import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Modal from '../Modal/Modal'
import { useEffect, useState } from 'react'
import Button from '../Button/Button'
import Input from '../Input/Input'
import { TrashIcon } from '@heroicons/react/24/solid'

type Props = {
  label: string
  value: string[]
  onChange: (value: string[]) => void
  disableSearch?: boolean
}

const ModalDynamicInput: React.FC<Props> = ({ label, value, onChange, disableSearch = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isOpen, setIsOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  const toggle = () => setIsOpen(!isOpen)

  const filter = (item: string) => {
    if (searchInput.length > 0 && !disableSearch) {
      return item.toLowerCase().includes(searchInput.toLowerCase())
    }
    return true
  }

  const updateInput = (index: number) => (updatedValue: string) => {
    const newValue = [...value]
    newValue[index] = updatedValue
    onChange(newValue)
  }

  const add = () => {
    onChange([...value, ''])
  }

  const remove = (index: number) => () => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  const confirm = () => {
    const cleanedValue = value.filter((v) => v.length > 0)
    onChange(cleanedValue)
    setIsOpen(false)
  }

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([])
    }
  }, [value, onChange])

  if (!Array.isArray(value)) return null
  return (
    <div>
      <label className="text-gray text-sm">{label}</label>
      <div className="flex flex-col">
        {Array.isArray(value) && <div>{value.join(', ')}</div>}
        <Button onClick={toggle} className="mt-1 text-left" text={t.generic.edit} small />
      </div>
      <Modal title={label} noForm onClose={toggle} show={isOpen} onConfirm={confirm}>
        <div className="flex flex-col gap-3">
          {!disableSearch && <Input placeholder={t.generic.search} value={searchInput} onChange={setSearchInput} />}
          <div className="flex flex-col gap-1 max-h-[500px] overflow-x-hidden overflow-y-auto">
            {value.filter(filter).map((v, i) => (
              <div key={i} className="flex items-center gap-3">
                <Input key={i} value={v} onChange={updateInput(i)} />
                <TrashIcon className="fill-gray hover:fill-red w-5 cursor-pointer" onClick={remove(i)} />
              </div>
            ))}
          </div>
          <Button onClick={add} text={t.generic.add} small />
        </div>
      </Modal>
    </div>
  )
}

export default ModalDynamicInput

import { functional } from '@think-internet/zeus-frontend-package'
import { Item } from '../../../../../../types/FileSystem'
import Routes from '../../../../../../redux/routes'
import useToast from '../../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'

const useDownloadFile = (returnURL = false) => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const downloadFile = functional.use(Routes.FILE_SYSTEM_GET_PRESIGNED_DOWNLOAD_URL)

  return (item: Item) => async () => {
    const presignedURL = await downloadFile({ key: item.key })
    if (presignedURL) {
      if (returnURL) {
        return presignedURL
      } else {
        const url = presignedURL
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${item.name}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    } else {
      if (!returnURL) {
        toast(t.fileSystem.itemTable.contextMenu.file.download.error)
      }
      return false
    }
  }
}

export default useDownloadFile

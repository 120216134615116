import { useEffect, useState } from 'react'
import ContextMenuOption from './ContextMenuOption'
import { Access } from '../../../../../types/Access'
import useFileSystem from '../../useFileSystem'
import { Item } from '../../../../../types/FileSystem'

export type ContextOption = {
  name: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  onClick?: () => void
  confirm?: string
  rename?: Item
  permission?: keyof Access
  isActive?: boolean
}

type ContextMenuProps = {
  children?: React.ReactNode
  options: ContextOption[]
  onClick?: () => void
  onDoubleClick?: () => void
  className?: string
}

export const ContextMenu: React.FC<ContextMenuProps> = ({ children, className, onClick, onDoubleClick, options }) => {
  const [contextMenuVisible, setContextMenuVisible] = useState(false)
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
  const { fileSystem } = useFileSystem()

  const handleRightClick = (e) => {
    e.preventDefault()
    if (options.filter((o) => o.isActive !== false).length > 0 && !fileSystem.isRoot) {
      setMenuPosition({ x: e.pageX, y: e.pageY })
      setContextMenuVisible(true)
    }
  }

  const onClose = () => {
    setContextMenuVisible(false)
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Close the context menu if clicking outside of it
      if (contextMenuVisible) {
        setContextMenuVisible(false)
      }
    }

    if (contextMenuVisible) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    // Clean up the event listener on component unmount or when menu is closed
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [contextMenuVisible])

  const internalOnClick = () => {
    if (onClick) {
      setContextMenuVisible(false)
      onClick()
    }
  }

  const internalOnDoubleClick = () => {
    if (onDoubleClick) {
      setContextMenuVisible(false)
      onDoubleClick()
    }
  }

  return (
    <div onContextMenu={handleRightClick} className={className} onClick={internalOnClick} onDoubleClick={internalOnDoubleClick}>
      {children}
      {!!contextMenuVisible && (
        <div
          style={{
            top: menuPosition.y,
            left: menuPosition.x,
          }}
          className="rounded-sm z-10 border border-blue shadow-md absolute bg-white divide-y divide-gray-200"
          onClick={(e) => e.stopPropagation()}
        >
          {options
            .filter((o) => o.isActive !== false)
            .map((option, index) => (
              <ContextMenuOption key={index} option={option} clickCallback={onClose} />
            ))}
        </div>
      )}
    </div>
  )
}

export default ContextMenu

type Props = {
  children?: React.ReactNode
  className?: string
}

const Cell: React.FC<Props> = ({ children, className = '' }) => {
  return <div className={`${className} p-1 whitespace-nowrap text-sm shrink-0 grow self-stretch`}>{children || ''}</div>
}

export default Cell

import { Summary } from '../EntryList'
import { formatNumber } from '../../../../../../utility'
import Cell from './Cell'

const TableFooter: React.FC<{ summary: Summary }> = ({ summary }) => {
  return (
    <div className="flex divide-x">
      <Cell className="bg-background border-t-2 border-blue w-[50px] whitespace-nowrap !text-md font-bold">S</Cell>
      <Cell className="bg-background border-t-2 border-blue w-[100px] font-bold"></Cell>
      <Cell className="bg-background border-t-2 border-blue w-[100px] font-bold"></Cell>
      <Cell className="bg-background border-t-2 border-blue w-[150px] whitespace-nowrap !text-md font-bold">{formatNumber(summary.totalHours)}</Cell>
      {summary.projects.map((p) => (
        <div key={p} className=" shrink-0 bg-background border-t-2 border-blue w-[300px] whitespace-nowrap text-md font-bold">
          <div className="flex divide-x">
            <Cell className="w-1/5 !text-md">{formatNumber(p)}</Cell>
            <Cell className="w-4/5 !text-md"></Cell>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TableFooter

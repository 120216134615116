import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { Item as ItemType } from '../../../../../types/FileSystem'
import Item from './Item/Item'
import { useCallback, useEffect, useState } from 'react'
import useFileSystem from '../../useFileSystem'
import useToast from '../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useClipboard from '../ContextMenu/hooks/useClipboard'

type Props = {
  items: ItemType[]
}

const DNDItemList: React.FC<Props> = ({ items }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const { copy, cut, paste, hasItem } = useClipboard()
  const { moveItem, fileSystem } = useFileSystem()
  const [itemUUIDInMoveState, setItemUUIDInMoveState] = useState<string>(null)

  // add a listener to copy the item to the clipboard
  useEffect(() => {
    const handleCopy = (e: ClipboardEvent) => {
      if (!fileSystem.activeSelectedItem) return
      e.preventDefault()
      copy(fileSystem.activeSelectedItem)()
    }

    // add a listener to paste the item from the clipboard
    const handlePaste = (e: ClipboardEvent) => {
      if (!hasItem) return
      e.preventDefault()
      paste()()
    }

    // add a listener to cut the item from the clipboard
    const handleCut = (e: ClipboardEvent) => {
      if (!fileSystem.activeSelectedItem) return
      e.preventDefault()
      cut(fileSystem.activeSelectedItem)()
    }

    document.addEventListener('copy', handleCopy)
    document.addEventListener('paste', handlePaste)
    document.addEventListener('cut', handleCut)

    return () => {
      document.removeEventListener('copy', handleCopy)
      document.removeEventListener('paste', handlePaste)
      document.removeEventListener('cut', handleCut)
    }
  }, [fileSystem.activeSelectedItem, items, copy, cut, paste, hasItem])

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor),
  )

  const handleDragEnd = useCallback(
    async ({ over, active }) => {
      if (!over || !active || over.id === active.id) return null

      const selectedItem = items.find((item) => item.uuid === active.id)
      const targetItem = items.find((item) => item.uuid === over.id)

      setItemUUIDInMoveState(selectedItem.uuid)
      const status = await moveItem(selectedItem.uuid, targetItem.key)
      setItemUUIDInMoveState(null)
      if (!status) {
        toast(t.fileSystem.itemTable.move.error)
      }
    },
    [items, moveItem, toast, t],
  )

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      {items.map((item) => (
        <Item key={item.uuid} item={item} inMoveState={item.uuid === itemUUIDInMoveState} />
      ))}
    </DndContext>
  )
}

export default DNDItemList

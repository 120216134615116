import { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { useEffect, useState } from 'react'
import useDownloadFile from '../ContextMenu/hooks/useDownloadFile'
import Loading from '../../../../UI/Loading/Loading'
import useFileSystem from '../../useFileSystem'
import { File, ItemType } from '../../../../../types/FileSystem'
import { StyledDocViewer } from './FilePreview.Styled'
import '@cyntler/react-doc-viewer/dist/index.css'
import { XMarkIcon } from '@heroicons/react/24/solid'

const FilePreview = () => {
  const { fileSystem, toggleFilePreview } = useFileSystem()
  const downloadFile = useDownloadFile(true)
  const [url, setUrl] = useState(null)
  const [currentItem, setCurrentItem] = useState<File>(null)

  useEffect(() => {
    const startDownload = async () => {
      if (fileSystem.activeSelectedItem.type === ItemType.FILE) {
        setUrl(null)
        const url = await downloadFile(fileSystem.activeSelectedItem)()
        if (!!url) {
          setCurrentItem(fileSystem.activeSelectedItem)
          setUrl(url)
        }
      }
    }
    if (fileSystem.activeSelectedItem && (!currentItem || currentItem.uuid !== fileSystem.activeSelectedItem.uuid)) {
      startDownload()
    } else if (!fileSystem.activeSelectedItem) {
      setUrl(null)
      setCurrentItem(null)
    }
  }, [downloadFile, url, fileSystem.activeSelectedItem, currentItem])

  const MyLoadingRenderer = ({ document, fileName }) => {
    return (
      <div className="h-full flex items-center justify-center">
        <Loading loading={false} />
      </div>
    )
  }

  if (!fileSystem.filePreviewVisibile) return null

  return (
    <div className="relative w-2/3 rounded-sm h-full bg-white shadow-sm overflow-hidden">
      <div className="flex justify-end p-2 w-full">
        <XMarkIcon className="h-5 cursor-pointer" onClick={() => toggleFilePreview()} />
      </div>
      <div className="w-full h-full overflow-hidden">
        {!!url && (
          <StyledDocViewer
            config={{
              loadingRenderer: {
                overrideComponent: MyLoadingRenderer,
              },
            }}
            prefetchMethod="GET"
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: url }]}
          />
        )}
      </div>
    </div>
  )
}

export default FilePreview

import { functional } from '@think-internet/zeus-frontend-package'
import { Item, ItemType } from '../../../../../../types/FileSystem'
import Routes from '../../../../../../redux/routes'
import useToast from '../../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { useState } from 'react'
import useFileSystem from '../../../useFileSystem'

const useRenameFile = (item?: Item) => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const submitRename = functional.use(Routes.FILE_SYSTEM_RENAME_ITEM)
  const [name, setName] = useState<string>(item?.name)
  const { fetchFiles, fileSystem } = useFileSystem()

  const submit = async () => {
    if (!item || !name) return
    const status = await submitRename({ uuid: item.uuid, name })
    await fetchFiles(fileSystem)
    if (!status) {
      if (item.type === ItemType.FOLDER) {
        toast(t.fileSystem.itemTable.contextMenu.folder.rename.error)
      } else {
        toast(t.fileSystem.itemTable.contextMenu.file.rename.error)
      }
    }
  }

  const set = (name: string) => {
    setName(name)
  }

  return { name, set, submit }
}

export default useRenameFile

import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Modal from '../Modal/Modal'
import { useEffect, useState } from 'react'
import Checklist from '../Checklist/Checklist'
import Button from '../Button/Button'
import Input from '../Input/Input'

type Props = {
  label: string
  ctaLabel?: string
  items: string[]
  value: string[]
  onChange: (value: string[]) => void
  resolveName: (uuid: string) => string
  disableResultList?: boolean
}

const ModalChecklist: React.FC<Props> = ({ label, ctaLabel, items, value, onChange, resolveName, disableResultList = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isOpen, setIsOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  const toggle = () => setIsOpen(!isOpen)

  const filter = (itemUUID: string) => {
    if (searchInput.length > 0) {
      const name = resolveName(itemUUID)
      if (!name) return false
      return name.toLowerCase().includes(searchInput.toLowerCase())
    }
    return true
  }

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([])
    }
  }, [value, onChange])

  if (!Array.isArray(items) || !Array.isArray(value)) return null
  return (
    <div>
      {!disableResultList && <label className="text-gray text-sm">{label}</label>}
      <div className="flex flex-col">
        {!disableResultList && Array.isArray(value) && <div>{value.map((v) => resolveName(v)).join(', ')}</div>}
        <Button onClick={toggle} className="mt-1 text-left" text={ctaLabel || t.generic.edit} small />
      </div>
      <Modal title={label} noForm onClose={toggle} show={isOpen} onConfirm={toggle}>
        <div className="flex flex-col gap-3">
          <Input placeholder={t.generic.search} value={searchInput} onChange={setSearchInput} />
          <Checklist
            className="max-h-[500px] overflow-x-hidden overflow-y-auto"
            value={value}
            items={items.filter(filter).map((i) => ({ label: resolveName(i), uuid: i }))}
            onChange={onChange}
          />
        </div>
      </Modal>
    </div>
  )
}

export default ModalChecklist

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import props from '../../../../../redux/props'
import { formatNumber } from '../../../../../utility'
import { TimetrackerFilter, TimetrackerSummary } from '../../../../../types/Timetracker'
import { useEffect, useState } from 'react'
import useTimetracker from '../../../../hooks/useContext/useTimetracker'
import _ from 'lodash'
import { Project } from '../../../../../types/Project'
import BudgetTracker from './BudgetTracker'

const PropWrapper = styled.div`
  width: calc(100% / 3 - (15px * 2 / 3));
`

export const Prop = ({ label, value }) => {
  return (
    <PropWrapper className="text-sm flex-1 flex gap-2 items-end">
      <div className="font-bold">{label}</div>
      <div>{value}</div>
    </PropWrapper>
  )
}

type Props = {
  filter: TimetrackerFilter
  initSummary?: TimetrackerSummary | false
  hideMemberHours?: boolean
  project?: Project
}

const Summary: React.FC<Props> = ({ filter, initSummary, hideMemberHours = false, project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { getSummary } = useTimetracker()
  const [summary, setSummary] = useState<TimetrackerSummary | false>(initSummary || null)
  const [currentFilter, setCurrentFilter] = useState<TimetrackerFilter>(filter)

  useEffect(() => {
    const load = async () => {
      const summary = await getSummary(filter)
      setSummary(summary)
      setCurrentFilter(filter)
    }

    if (!initSummary && (!summary || !_.isEqual(currentFilter, filter))) {
      load()
    }
  }, [summary, getSummary, filter, currentFilter, initSummary])

  if (!summary || summary.totalHours === 0) return null
  return (
    <div className="flex flex-col gap-2 py-3">
      <BudgetTracker summary={summary} />
      <div>
        <div className="underline text-sm">{t.timetracking.summary.generic}</div>
        <div className="flex gap-[15px]">
          <Prop label={t.timetracking.summary.totalHours} value={formatNumber(summary.totalHours)} />
          <Prop label={t.timetracking.summary.totalEntries} value={formatNumber(summary.entryAmount)} />
        </div>
      </div>
      {!hideMemberHours && summary.memberHours.length > 0 && (
        <div>
          <div className="underline text-sm">{t.timetracking.summary.byEmployee}</div>
          <div className="flex gap-[15px]">
            {summary.memberHours.map((m) => (
              <Prop key={m.name} label={`${m.name}:`} value={formatNumber(m.hours)} />
            ))}
          </div>
        </div>
      )}
      {summary.areaHours.length > 0 && (
        <div>
          <div className="underline text-sm">{t.timetracking.summary.byArea}</div>
          <div className="flex gap-[15px]">
            {summary.areaHours.map((area) => (
              <Prop key={area.area} label={`${area.area}:`} value={formatNumber(area.hours)} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Summary

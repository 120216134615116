import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useTimetracker from '../../../../hooks/useContext/useTimetracker'
import { useParams } from 'react-router-dom'
import { TimetrackerEntry, TimetrackerFilter } from '../../../../../types/Timetracker'
import { fitsInDateRange, getWeekOfYear, getPrettyDate } from '../../../../../utility'
import FullHeightWrapper from '../../../../UI/FullHeightWrapper/FullHeightWrapper'
import LargeContainer from '../../../../UI/Container/LargeContainer'
import FilterConfigModal from '../../FilterConfigModal/FilterConfigModal'
import EntryList from './EntryList'
import useOwnMember from '../../../../hooks/useContext/useOwnMember'

export type OnChange = (uuid: string) => (key: keyof TimetrackerEntry) => (value: any) => void

const MemberView: React.FC<{}> = () => {
  const { memberUUID } = useParams()
  const t = useSelector((s) => s[props.TRANSLATION])

  const getCurrentWeek = () => {
    const currentDate = new Date()
    const currentDay = currentDate.getDay()
    const startDate = new Date(currentDate)
    startDate.setDate(startDate.getDate() - currentDay + 1)
    const endDate = new Date(startDate)
    endDate.setDate(endDate.getDate() + 6)
    return {
      startDate,
      endDate,
    }
  }

  const [filter, setFilter] = useState<TimetrackerFilter>({
    memberUUID,
    includeIsMeeting: true,
    includeNotBillable: true,
    dateRange: getCurrentWeek(),
  })
  const [entries, setEntries] = useState<TimetrackerEntry[]>()
  const { pull } = useTimetracker()
  const member = useOwnMember()

  const fetchEntries = useCallback(async () => {
    const entries = await pull(null, memberUUID)
    if (!!entries) {
      setEntries(entries)
    }
  }, [memberUUID, pull])

  useEffect(() => {
    if (!entries) {
      fetchEntries()
    }
  }, [filter, pull, fetchEntries, memberUUID, entries])

  const applyFilter = (entries: TimetrackerEntry[]) => {
    if (!entries) return []
    return entries.filter((entry) => {
      const memberCheck =
        !filter.memberUUID ||
        (Array.isArray(filter.memberUUID) && filter.memberUUID.includes(entry.memberUUID)) ||
        filter.memberUUID === entry.memberUUID
      const entryDate = new Date(entry.date)
      const dateRangeCheck = !!filter.dateRange ? fitsInDateRange(entryDate, filter.dateRange) : true
      const includeIsMeetingCheck = filter.includeIsMeeting ? true : !entry.isMeeting
      const includeNotBillableCheck = filter.includeNotBillable ? true : !entry.notBillable
      const onlyMeetingsCheck = filter.onlyMeetings ? entry.isMeeting : true
      const modeCheck = !!filter.mode ? filter.mode === entry.mode : true
      const projectCheck = !filter.projectUUID || filter.projectUUID === entry.projectUUID
      return memberCheck && dateRangeCheck && includeIsMeetingCheck && includeNotBillableCheck && onlyMeetingsCheck && modeCheck && projectCheck
    })
  }

  const getKWLabel = () => {
    if (!filter.dateRange) return null
    const week = getWeekOfYear(filter.dateRange.startDate)
    const start = getPrettyDate(filter.dateRange.startDate)
    const end = getPrettyDate(filter.dateRange.endDate)
    const year = new Date(filter.dateRange.startDate).getFullYear()
    return `${week}. KW ${year} (${start} - ${end})`
  }

  if (!member) return null
  return (
    <FullHeightWrapper>
      <LargeContainer>
        <div className="flex flex-col gap-1 pt-10 pb-20">
          <div className="rounded-sm shadow-sm bg-white p-3">
            <div className="flex justify-between border-b pb-1 border-blue">
              <div className="font-bold text-xl">
                <div className="font-bold text-sm">{t.timetracking.title}</div>
                <div>{`${member.firstname} ${member.lastname}`}</div>
              </div>
              <div className="flex gap-3 items-center">{getKWLabel()}</div>
              <div className="flex gap-3 items-center">
                <FilterConfigModal enableWeekSelection disableMemberSelection filterConfig={filter} setFilterConfig={setFilter} />
              </div>
            </div>
            <EntryList entries={applyFilter(entries)} filter={filter} pull={fetchEntries} />
          </div>
        </div>
      </LargeContainer>
    </FullHeightWrapper>
  )
}

export default MemberView

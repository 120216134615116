import { useSelector } from 'react-redux'
import { formatNumber, getPrettyDate, getWeekOfYear } from '../../../../../../utility'
import props from '../../../../../../redux/props'
import HasPermission from '../../../../HasPermission/HasPermission'
import { TimetrackerEntry } from '../../../../../../types/Timetracker'
import InlineEntryCustomizer from '../../MemberView/Entry/InlineEntryCustomizer'
import Button from '../../../../../UI/Button/Button'

type Props = {
  entry: TimetrackerEntry
  pull?: (projectUUID?: string) => void
}

const MinifiedEntry: React.FC<Props> = ({ entry, pull }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="py-1">
      <div className="flex items-center">
        <div className="w-1/3 truncate font-bold">{`${entry.member.firstname} ${entry.member.lastname}`}</div>
        <div className="w-1/3 truncate text-sm">
          {t.generic.calendarWeekPrefix}
          {getWeekOfYear(new Date(entry.date))}
        </div>
        <div className="w-1/3 truncate text-sm">
          {t.generic.datePrefix}
          {getPrettyDate(entry.date)}
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/3 truncate text-sm">{`${t.generic.hourPrefix}${formatNumber(entry.hours)}`}</div>
        {entry.notBillable && (
          <HasPermission permission="timetackerAccess">
            <div className={`font-bold text-gray text-sm w-1/3 truncate`}>{t.fileSystem.toolbar.timetracker.notBillable}</div>
          </HasPermission>
        )}
        {entry.isMeeting && <div className={`font-bold text-gray text-sm w-1/3 truncate`}>{t.fileSystem.toolbar.timetracker.isMeeting}</div>}
      </div>
      <div className="flex items-center">
        {!!entry.area && (
          <div className="text-sm flex-1 truncate">
            {t.fileSystem.toolbar.timetracker.entryList.areaPrefix}
            {entry.area}
          </div>
        )}
      </div>
      <div className="text-sm whitespace-pre-wrap">{entry.description}</div>
      <HasPermission permission="timetackerUpdateEntry">
        <InlineEntryCustomizer entry={entry} pull={pull} CustomTrigger={<Button small className="mt-1 ml-auto" text={t.generic.edit} />} />
      </HasPermission>
    </div>
  )
}

export default MinifiedEntry

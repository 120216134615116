import DefaultActionButton from '../DefaultActionButton'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import useToast, { Type } from '../../../../hooks/useToast'
import { Timer as TimerIcon } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import Modal from '../../../../UI/Modal/Modal'
import HasPermission from '../../../HasPermission/HasPermission'
import useTimetracker from '../../../../hooks/useContext/useTimetracker'
import { TimetrackerEntry, TimetrackerEntryMode } from '../../../../../types/Timetracker'
import EntryList from '../../../Timetracking/Project/View/EntryList'
import _ from 'lodash'
import Button from '../../../../UI/Button/Button'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import UpsertForm from '../../../Timetracking/Entry/UpsertForm'

type Props = {
  projectUUID?: string
}

const Timetracker: React.FC<Props> = ({ projectUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const timetracker = useTimetracker()
  const toast = useToast()
  const emptyEntry: TimetrackerEntry = {
    projectUUID,
    description: '',
    hours: 0,
    date: new Date(),
    notBillable: false,
    isMeeting: false,
    mode: TimetrackerEntryMode.ARBEITSTAG,
  }
  const [data, setData] = useState<TimetrackerEntry>(_.cloneDeep(emptyEntry))
  const [isOpen, setIsOpen] = useState(false)
  const { pull } = useTimetracker(true)
  const [entries, setEntries] = useState<TimetrackerEntry[]>(null)
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const fetch = useCallback(
    async (projectUUID: string) => {
      if (!!projectUUID) {
        const entries = await pull(projectUUID)
        if (entries) {
          setEntries(entries)
        }
      }
    },
    [pull, setEntries],
  )

  useEffect(() => {
    if (!entries) {
      fetch(data.projectUUID)
    }
  }, [fetch, entries, data.projectUUID])

  const save = async () => {
    const entry = await timetracker.add(data)
    if (entry) {
      setData(_.cloneDeep(emptyEntry))
      fetch(data.projectUUID)
      toast(t.fileSystem.toolbar.timetracker.success, Type.SUCCESS)
    } else {
      toast(t.fileSystem.toolbar.timetracker.error)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  const set = (key: keyof TimetrackerEntry) => (value: any) => {
    if (key === 'projectUUID') {
      setData({ ...data, [key]: value, area: null })
      fetch(value)
    } else {
      setData({ ...data, [key]: value })
    }
  }

  if (!data) return null
  return (
    <HasPermission permission="timetackerCreateEntry">
      <DefaultActionButton onClick={toggle} Icon={TimerIcon} name={t.fileSystem.toolbar.timetracker.cta} />
      <Modal
        large
        onClose={toggle}
        show={isOpen}
        onConfirm={save}
        title={t.fileSystem.toolbar.timetracker.title}
        actionButton={
          !!accountLink && (
            <Button
              small
              text={t.fileSystem.toolbar.timetracker.showMemberTimetracking}
              href={`/dashboard/timetracking/member/${accountLink.assignedToUUID}`}
            />
          )
        }
      >
        <div className="flex gap-5">
          <div className="flex-1">
            <div className="flex flex-col gap-3">
              <UpsertForm data={data} set={set} />
            </div>
          </div>
          <div className="flex-1 relative">
            <div className="absolute w-full h-full flex flex-col">
              <div className="text-sm text-gray">{t.fileSystem.toolbar.timetracker.memberEntriesOfProject}</div>
              <div className="h-full overflow-x-hidden overflow-y-auto py-2">
                <EntryList entries={entries} pull={fetch} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </HasPermission>
  )
}

export default Timetracker

import DocViewer from '@cyntler/react-doc-viewer'
import styled from 'styled-components'

export const StyledDocViewer = styled(DocViewer)`
  height: 100%;
  flex: stretch;
  overflow: auto;
  #header-bar,
  #pdf-download {
    display: none;
  }
  #proxy-renderer,
  #msdoc-renderer {
    height: 100%;
  }
  #pdf-controls {
    z-index: 10;
  }
`

import { ClipboardPaste } from 'lucide-react'
import { ContextOption } from './ContextMenu'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useClipboard from './hooks/useClipboard'

const useGetFileListOptions = (): (() => ContextOption[]) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const clipboard = useClipboard()

  return () => {
    return [
      {
        Icon: ClipboardPaste,
        name: t.fileSystem.itemTable.contextMenu.folder.paste.cta,
        onClick: clipboard.paste(),
        isActive: clipboard.hasItem,
      },
    ]
  }
}

export default useGetFileListOptions

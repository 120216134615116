import { useSelector } from 'react-redux'
import HasPermission from '../../../HasPermission/HasPermission'
import { Prop } from './Summary'
import props from '../../../../../redux/props'
import { TimetrackerSummary } from '../../../../../types/Timetracker'
import { formatEUR, formatNumber, round } from '../../../../../utility'
import Status, { StatusType } from '../../../../UI/Status/Status'

type Props = {
  summary: TimetrackerSummary
}

const BudgetTracker: React.FC<Props> = ({ summary }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const hasBudget = summary.budgetTracker.total > 0

  const StatusBell = () => {
    let status: StatusType = StatusType.GOOD
    if (summary.budgetTracker.usedPercentage >= 90) {
      status = StatusType.BAD
    } else if (summary.budgetTracker.usedPercentage > 80) {
      status = StatusType.MEDIUM
    }
    return (
      <div>
        <Status status={status} />
      </div>
    )
  }

  return (
    <HasPermission permission="budgetManagement">
      <div>
        <div className="underline text-sm">{t.timetracking.summary.budgetTracker.title}</div>
        {hasBudget ? (
          <div className="flex gap-[15px]">
            <div className="flex items-center gap-2 flex-1">
              <StatusBell />
              <div className="whitespace-nowrap text-sm">
                {formatNumber(round(summary.budgetTracker.usedPercentage))}
                {t.generic.percent}
              </div>
            </div>
            <Prop label={t.timetracking.summary.budgetTracker.total} value={formatEUR(summary.budgetTracker.total)} />
            <Prop label={t.timetracking.summary.budgetTracker.used} value={formatEUR(summary.budgetTracker.used)} />
            <Prop label={t.timetracking.summary.budgetTracker.remaining} value={formatEUR(summary.budgetTracker.remaining)} />
          </div>
        ) : (
          <div className="text-sm">{t.timetracking.summary.budgetTracker.noBudget}</div>
        )}
      </div>
    </HasPermission>
  )
}

export default BudgetTracker

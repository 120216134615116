import { ClipboardPaste, Copy, Download, PenLine, SquareBottomDashedScissors, Trash } from 'lucide-react'
import { Item } from '../../../../../types/FileSystem'
import { ContextOption } from './ContextMenu'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useRemoveItem from './hooks/useRemoveItem'
import useDownloadFile from './hooks/useDownloadFile'
import useClipboard from './hooks/useClipboard'

const useGetFileOptions = (): ((item: Item) => ContextOption[]) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const clipboard = useClipboard()
  const removeItem = useRemoveItem()
  const downloadFile = useDownloadFile()

  return (item: Item) => {
    return [
      {
        Icon: Copy,
        name: t.fileSystem.itemTable.contextMenu.file.copy.cta,
        onClick: clipboard.copy(item),
        permission: 'moveFiles',
      },
      {
        Icon: SquareBottomDashedScissors,
        name: t.fileSystem.itemTable.contextMenu.file.cut.cta,
        onClick: clipboard.cut(item),
        permission: 'moveFiles',
      },
      {
        Icon: ClipboardPaste,
        name: t.fileSystem.itemTable.contextMenu.file.paste.cta,
        onClick: clipboard.paste(item),
        isActive: clipboard.hasItem,
      },
      {
        Icon: PenLine,
        name: t.fileSystem.itemTable.contextMenu.file.rename.cta,
        rename: item,
        permission: 'moveFiles',
      },
      {
        Icon: Trash,
        name: t.fileSystem.itemTable.contextMenu.file.remove.cta,
        confirm: t.fileSystem.itemTable.contextMenu.file.remove.confirm(item),
        onClick: removeItem(item),
        permission: 'deleteFiles',
      },
      {
        Icon: Download,
        name: t.fileSystem.itemTable.contextMenu.file.download.cta,
        onClick: downloadFile(item),
      },
    ]
  }
}

export default useGetFileOptions

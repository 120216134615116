import { useState } from 'react'
import { ItemType } from '../../../../types/FileSystem'
import useFileSystem from '../useFileSystem'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { ItemWrapper, RowWrapper } from './Elements'
import DNDItemList from './DNDItemList/DNDItemList'
import ContextMenu from './ContextMenu/ContextMenu'
import useGetFileListOptions from './ContextMenu/useGetFileListOptions'

enum SortArg {
  NAME = 'NAME',
  SIZE = 'SIZE',
  DATE = 'DATE',
}

enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

const ItemTable = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [sortBy, setSortBy] = useState<SortArg>(SortArg.NAME)
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC)
  const { fileSystem } = useFileSystem()
  const contextMenuUptions = useGetFileListOptions()

  const getSortedFiles = () =>
    [...fileSystem.items].sort((a, b) => {
      if (sortBy === SortArg.DATE) {
        const aDate = a.type === ItemType.FOLDER ? a.createdOn : a.updatedDate
        const bDate = b.type === ItemType.FOLDER ? b.createdOn : b.updatedDate
        if (aDate < bDate) return sortOrder === SortOrder.ASC ? -1 : 1
        if (aDate > bDate) return sortOrder === SortOrder.ASC ? 1 : -1
        return 0
      } else if (sortBy === SortArg.SIZE) {
        const aSize = a.size
        const bSize = b.size
        if (aSize < bSize) return sortOrder === SortOrder.ASC ? -1 : 1
        if (aSize > bSize) return sortOrder === SortOrder.ASC ? 1 : -1
        return 0
      } else if (sortBy === SortArg.NAME) {
        const aName = a.name.toLowerCase()
        const bName = b.name.toLowerCase()
        if (aName < bName) return sortOrder === SortOrder.ASC ? -1 : 1
        if (aName > bName) return sortOrder === SortOrder.ASC ? 1 : -1
        return 0
      } else {
        return 0
      }
    })

  const handleSort = (column: SortArg) => () => {
    if (sortBy === column) {
      setSortOrder(sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC)
    } else {
      setSortBy(column)
      setSortOrder(SortOrder.ASC)
    }
  }

  if (!Array.isArray(fileSystem.items)) return null
  return (
    <div className="rounded-sm w-full bg-white shadow-sm h-full flex flex-col overflow-hidden">
      <RowWrapper className="border-b">
        <ItemWrapper small={false} className="font-bold" onClick={handleSort(SortArg.NAME)}>
          {t.fileSystem.itemTable.header.name}
        </ItemWrapper>
        <ItemWrapper className="font-bold" onClick={handleSort(SortArg.SIZE)}>
          {t.fileSystem.itemTable.header.size}
        </ItemWrapper>
        <ItemWrapper className="font-bold" onClick={handleSort(SortArg.DATE)}>
          {t.fileSystem.itemTable.header.updatedDate}
        </ItemWrapper>
      </RowWrapper>
      <div className="overflow-x-hidden overflow-y-auto h-full">
        <DNDItemList items={getSortedFiles()} />
        {/* <div className="overflow-x-hidden overflow-y-auto">
          {getSortedFiles().map((item) => (
            <Item key={item.uuid} item={item} />
          ))}
        </div> */}
        <ContextMenu options={contextMenuUptions()} className="h-full"></ContextMenu>
      </div>
    </div>
  )
}

export default ItemTable

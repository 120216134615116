import { useEffect, useState } from 'react'
import { Project } from '../../../../types/Project'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../redux/props'
import useToast, { Type } from '../../../hooks/useToast'
import { useSelector } from 'react-redux'

type Save = () => void
type Set = (key: keyof Project) => (value: any) => void
type Response = [Project, Set, Save]

const useProject = (uuid: string): Response => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const projectFeature = crud.use(props.PROJECT)
  const [project, setProject] = useState<Project>(null)

  useEffect(() => {
    const get = async () => {
      const result = await projectFeature.get({ uuid })
      if (!!result) {
        setProject(result)
      }
    }
    if ((!!uuid && !project) || project?.uuid !== uuid) {
      get()
    }
  }, [uuid, project, projectFeature])

  const save: Save = async () => {
    const result = await projectFeature.update(project)
    if (!!result) {
      setProject(result)
      toast(t.project.update.success, Type.SUCCESS)
    } else {
      toast(t.project.update.error, Type.ERROR)
    }
  }

  const set: Set = (key: keyof Project) => (value: any) => setProject({ ...project, [key]: value })

  return [project, set, save]
}

export default useProject

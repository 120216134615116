import { useSelector } from 'react-redux'
import { ClipboardTransitMode, Item, ItemType } from '../../../../../../types/FileSystem'
import useToast, { Type } from '../../../../../hooks/useToast'
import useFileSystem from '../../../useFileSystem'
import props from '../../../../../../redux/props'

type Copy = (item: Item) => () => void
type Cut = (item: Item) => () => void
type Paste = (targetItem?: Item) => () => void

const useClipboard = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const { fileSystem, updateClipboard, moveItem, copyItem } = useFileSystem()

  const copy: Copy = (item: Item) => () => {
    updateClipboard({ itemInTransit: item, transitMode: ClipboardTransitMode.COPY })
    toast(t.fileSystem.itemTable.contextMenu.clipboard.copy, Type.SUCCESS)
  }

  const cut: Cut = (item: Item) => () => {
    updateClipboard({ itemInTransit: item, transitMode: ClipboardTransitMode.CUT })
    toast(t.fileSystem.itemTable.contextMenu.clipboard.cut, Type.SUCCESS)
  }

  const paste: Paste = (targetItem?: Item) => async () => {
    if (!fileSystem?.clipboard?.itemInTransit) return false
    const { itemInTransit, transitMode } = fileSystem.clipboard
    let targetPrefix = fileSystem.activePrefix
    if (!!targetItem) {
      if (targetItem.type === ItemType.FOLDER) {
        targetPrefix = targetItem.key
      } else if (targetItem.type === ItemType.FILE) {
        targetPrefix = targetItem.prefix
      } else {
        return false
      }
    }

    if (transitMode === ClipboardTransitMode.CUT) {
      await moveItem(itemInTransit.uuid, targetPrefix)
      toast(t.fileSystem.itemTable.contextMenu.clipboard.paste, Type.SUCCESS)
    } else if (transitMode === ClipboardTransitMode.COPY) {
      await copyItem(itemInTransit.uuid, targetPrefix)
      toast(t.fileSystem.itemTable.contextMenu.clipboard.paste, Type.SUCCESS)
    }
  }

  return { copy, cut, paste, hasItem: !!fileSystem?.clipboard?.itemInTransit }
}

export default useClipboard

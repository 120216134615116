import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useTimetracker from '../../../../hooks/useContext/useTimetracker'
import { useParams } from 'react-router-dom'
import { TimetrackerEntry, TimetrackerEntryMode, TimetrackerFilter } from '../../../../../types/Timetracker'
import { fitsInDateRange } from '../../../../../utility'
import Summary from '../Summary/Summary'
import FullHeightWrapper from '../../../../UI/FullHeightWrapper/FullHeightWrapper'
import LargeContainer from '../../../../UI/Container/LargeContainer'
import useProject from '../../../Projects/Update/useProject'
import FilterConfigModal from '../../FilterConfigModal/FilterConfigModal'
import Export from '../../../../UI/Export/Export'
import Routes from '../../../../../redux/routes'
import EntryList from './EntryList'
import { functional } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../../hooks/useToast'
import SaveBar from '../../../../UI/SaveBar/SaveBar'

export type OnChange = (uuid: string) => (key: keyof TimetrackerEntry) => (value: any) => void

const View: React.FC<{}> = () => {
  const { projectUUID } = useParams()
  const t = useSelector((s) => s[props.TRANSLATION])
  const [filter, setFilter] = useState<TimetrackerFilter>({
    projectUUID,
    includeIsMeeting: true,
    includeNotBillable: true,
    mode: TimetrackerEntryMode.ARBEITSTAG,
  })
  const [entries, setEntries] = useState<TimetrackerEntry[]>()
  const [project] = useProject(projectUUID)
  const { pull } = useTimetracker()
  const runBuldUpdate = functional.use(Routes.BULK_ENTRY_UPDATE)
  const toast = useToast()

  const fetchEntries = useCallback(async () => {
    const entries = await pull(projectUUID)
    if (!!entries) {
      setEntries(entries)
    }
  }, [pull, projectUUID])

  useEffect(() => {
    if (!entries) {
      fetchEntries()
    }
  }, [filter, fetchEntries, entries])

  const applyFilter = (entries: TimetrackerEntry[]) => {
    if (!entries) return []
    return entries.filter((entry) => {
      const memberCheck =
        !filter.memberUUID ||
        (Array.isArray(filter.memberUUID) && filter.memberUUID.includes(entry.memberUUID)) ||
        filter.memberUUID === entry.memberUUID
      const entryDate = new Date(entry.date)
      const dateRangeCheck = !!filter.dateRange ? fitsInDateRange(entryDate, filter.dateRange) : true
      const includeIsMeetingCheck = filter.includeIsMeeting ? true : !entry.isMeeting
      const includeNotBillableCheck = filter.includeNotBillable ? true : !entry.notBillable
      const onlyMeetingsCheck = filter.onlyMeetings ? entry.isMeeting : true
      const modeCheck = !!filter.mode ? filter.mode === entry.mode : true
      return memberCheck && dateRangeCheck && includeIsMeetingCheck && includeNotBillableCheck && onlyMeetingsCheck && modeCheck
    })
  }

  const onChange: OnChange = (uuid) => (key) => (value) => {
    const updatedEntries = entries.map((e) => {
      if (e.uuid === uuid) {
        return { ...e, [key]: value }
      }
      return e
    })
    setEntries(updatedEntries)
  }

  const buldUpdate = async () => {
    const status = await runBuldUpdate({ entries })
    if (status) {
      toast(t.timetracking.success.bulkUpdate, Type.SUCCESS)
    } else {
      toast(t.timetracking.error.bulkUpdate)
    }
  }

  if (!project) return null
  return (
    <FullHeightWrapper>
      <LargeContainer>
        <div className="flex flex-col gap-1 pt-10 pb-20">
          <div className="rounded-sm shadow-sm bg-white p-3">
            <div className="flex justify-between border-b pb-1 border-blue">
              <div className="font-bold text-xl">
                <div className="font-bold text-sm">{t.timetracking.title}</div>
                {project.name}
              </div>
              <div className="flex gap-3 items-center">
                <FilterConfigModal disableProjectSelection filterConfig={filter} setFilterConfig={setFilter} />
                <Export
                  filter={filter}
                  context={{
                    fileName: 'Zeiterfassung',
                    headline: 'Zeiterfassung',
                  }}
                  route={Routes.GENERATE_TIMETRACKING_EXPORT}
                  translationProp="timetracking"
                  availableProperties={[
                    'project.name',
                    'member.firstname',
                    'member.lastname',
                    'area',
                    'description',
                    'hours',
                    'date',
                    'calendarWeek',
                    'notBillable',
                    'isMeeting',
                  ]}
                  preSelectedProperties={['project.name', 'area', 'description', 'hours', 'date', 'calendarWeek']}
                />
              </div>
            </div>
            <div className="border-b border-blue">
              <Summary filter={filter} />
            </div>
            <div className="py-3">
              <EntryList entries={applyFilter(entries)} onChange={onChange} pull={fetchEntries} />
            </div>
            <SaveBar onSave={buldUpdate} />
          </div>
        </div>
      </LargeContainer>
    </FullHeightWrapper>
  )
}

export default View

import { useSelector } from 'react-redux'
import { TimetrackerEntry, TimetrackerEntryMode } from '../../../../types/Timetracker'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import DaySelector from '../../../UI/DaySelector/DaySelector'
import MemberSelector from '../../../UI/MemberSelector/MemberSelector'
import ProjectAreaSelector from '../../../UI/ProjectAreaSelector/ProjectAreaSelector'
import ProjectSelector from '../../../UI/ProjectSelector/ProjectSelector'
import Textarea from '../../../UI/Textarea/Textarea'
import TimetrackerEntryModeSelector from '../../../UI/TimetrackerEntryModeSelector/TimetrackerEntryModeSelector'
import HasPermission from '../../HasPermission/HasPermission'
import props from '../../../../redux/props'
import Number from '../../../UI/Input/Number'

type Props = {
  data: TimetrackerEntry
  set: (key: string) => (value: any) => void
}

const UpsertForm: React.FC<Props> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const isWorkingDay = data.mode === TimetrackerEntryMode.ARBEITSTAG || data.mode === TimetrackerEntryMode.FORTBILDUNGSTAG

  return (
    <div className="flex flex-col gap-3">
      <TimetrackerEntryModeSelector value={data.mode} onChange={set('mode')} />
      {isWorkingDay && (
        <>
          <ProjectSelector required value={data.projectUUID} onChange={set('projectUUID')} />
          <ProjectAreaSelector required projectUUID={data.projectUUID} value={data.area} onChange={set('area')} />
          <Textarea required label={t.fileSystem.toolbar.timetracker.description} value={data.description} onChange={set('description')} />
          <Number decimal required label={t.fileSystem.toolbar.timetracker.hours} value={data.hours} onChange={set('hours')} />
        </>
      )}
      <DaySelector required label={t.fileSystem.toolbar.timetracker.date} value={data.date} onChange={set('date')} />
      <HasPermission permission="timetackerAccess">
        <Checkbox label={t.fileSystem.toolbar.timetracker.notBillable} checked={data.notBillable} onChange={set('notBillable')} />
      </HasPermission>
      <Checkbox label={t.fileSystem.toolbar.timetracker.isMeeting} checked={data.isMeeting} onChange={set('isMeeting')} />
      {data.isMeeting && (
        <MemberSelector
          label={t.fileSystem.toolbar.timetracker.selectAdditionalMember}
          ctaLabel={t.generic.select}
          excludeSelf
          multiple
          value={data.additionalMemberUUIDList}
          onChange={set('additionalMemberUUIDList')}
        />
      )}
    </div>
  )
}

export default UpsertForm

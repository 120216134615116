import { getWeekOfYear } from '../../../../../../utility'
import { TimetrackerEntry } from '../../../../../../types/Timetracker'
import Number from '../../../../../UI/Input/Number'
import ProjectAreaSelector from '../../../../../UI/ProjectAreaSelector/ProjectAreaSelector'
import Textarea from '../../../../../UI/Textarea/Textarea'
import DaySelector from '../../../../../UI/DaySelector/DaySelector'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import HasPermission from '../../../../HasPermission/HasPermission'
import RemoveConfirmation from '../../../../../UI/RemoveConfirmation/RemoveConfirmation'
import useToast, { Type } from '../../../../../hooks/useToast'
import useTimetracker from '../../../../../hooks/useContext/useTimetracker'
import { TrashIcon } from '@heroicons/react/24/solid'

type Props = {
  entry: TimetrackerEntry
  onChange: (key: keyof TimetrackerEntry) => (value: any) => void
  pull: (projectUUID?: string) => void
}

const inputClass = 'border-none !bg-lightGray !text-blue'

const TableEntry: React.FC<Props> = ({ entry, onChange, pull }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const timetracker = useTimetracker()

  const remove = async () => {
    const status = await timetracker.remove(entry.uuid)
    if (status) {
      pull()
      toast(t.timetracking.remove.success, Type.SUCCESS)
    } else {
      toast(t.timetracking.remove.error)
    }
  }

  return (
    <div className="py-1">
      <div className="flex items-center">
        {/* <div className="w-[60px] break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.laufendeNummer}</div> */}
        <div className="w-[60px] break-words text-sm shrink-0 grow-0">{`${entry.number}.`}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1">{getWeekOfYear(new Date(entry.date))}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1">{t.UI.timetrackerEntryModeSelector.modes[entry.mode]}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1">
          <Number contentClassName={inputClass} decimal value={entry.hours} onChange={onChange('hours')} />
        </div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1">
          <DaySelector contentClassName={inputClass} value={entry.date} onChange={onChange('date')} />
        </div>
        <div className="w-2/12 break-words text-sm shrink-0 grow-0 px-1">{`${entry.member.firstname} ${entry.member.lastname}`}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1">
          <ProjectAreaSelector
            contentClassName={inputClass}
            disableLabel
            projectUUID={entry.projectUUID}
            value={entry.area}
            onChange={onChange('area')}
          />
        </div>
        <HasPermission permission="timetackerAccess">
          <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1 flex justify-center">
            <Checkbox width="w-fit" checked={!entry.notBillable} onChange={(v) => onChange('notBillable')(!v)} />
          </div>
        </HasPermission>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1 flex justify-center">
          <Checkbox width="w-fit" checked={entry.isMeeting} onChange={onChange('isMeeting')} />
        </div>
        <div className="w-3/12 break-words text-sm  px-1">
          <Textarea contentClassName="!w-full !bg-lightGray text-blue border-none" value={entry.description} onChange={onChange('description')} />
        </div>
        <HasPermission permission="timetackerRemoveEntry">
          <RemoveConfirmation confirmationCallback={remove}>
            <TrashIcon className="fill-gray hover:fill-red w-5 cursor-pointer" />
          </RemoveConfirmation>
        </HasPermission>
      </div>
    </div>
  )
}

export default TableEntry

import { useSelector } from 'react-redux'
import { TimetrackerEntry, TimetrackerEntryMode, TimetrackerFilter } from '../../../../../types/Timetracker'
import props from '../../../../../redux/props'
import TableEntry from './Entry/TableEntry'
import TableHeader from './Entry/TableHeader'
import { getPrettyDate, reversePrettyDate } from '../../../../../utility'
import Loading from '../../../../UI/Loading/Loading'
import TableFooter from './Entry/TableFooter'

type Props = { entries?: TimetrackerEntry[]; filter: TimetrackerFilter; pull: () => void }

type DayProject = { entries: TimetrackerEntry[]; totalHours: number; projectName: string }

export type DateCluster = { label: string; projects: DayProject[]; totalHours: number; modes: TimetrackerEntryMode[] }

export type Summary = { totalHours: number; projects: number[] }

// used for entries with mode besides ARBEITSTAG or FORTBILDUNG
const INTERNAL_PROJECT = 'Intern'

const EntryList: React.FC<Props> = ({ entries, filter, pull }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getUniqueDays = () => {
    if (filter.dateRange && filter.dateRange.startDate && filter.dateRange.endDate) {
      const { startDate, endDate } = filter.dateRange
      const date = new Date(startDate)
      const entries = []
      while (date <= endDate) {
        entries.push(getPrettyDate(date))
        date.setDate(date.getDate() + 1)
      }
      return entries
    }
    return entries
      .reduce((acc, e) => {
        const date = getPrettyDate(e.date)
        if (!acc.includes(date)) {
          acc.push(date)
        }
        return acc
      }, [] as string[])
      .sort((a, b) => {
        return reversePrettyDate(b).getTime() - reversePrettyDate(a).getTime()
      })
  }

  const getUniqueProjects = () => {
    return entries
      .reduce((acc, e) => {
        if (!!e.project && !acc.includes(e.project.name)) {
          acc.push(e.project.name)
        } else {
          if (!acc.includes(INTERNAL_PROJECT)) {
            acc.push(INTERNAL_PROJECT)
          }
        }
        return acc
      }, [] as string[])
      .sort()
  }

  const getEntriesOfDate = (date: string) => {
    return entries.filter((e) => getPrettyDate(e.date) === date)
  }

  const getDateCluster = () => {
    const uniqueDays = getUniqueDays()
    return uniqueDays.reduce((acc, day) => {
      const dayEntries = getEntriesOfDate(day)
      const totalHours = dayEntries.reduce((acc, e) => {
        if (!e.project) {
          return acc + 8
        }
        return acc + e.hours
      }, 0)
      const modes = dayEntries.reduce((acc, e) => {
        if (!acc.includes(e.mode as TimetrackerEntryMode)) {
          acc.push(e.mode as TimetrackerEntryMode)
        }
        return acc
      }, [] as TimetrackerEntryMode[])
      const projects = getUniqueProjects().map((p) => {
        let entries: TimetrackerEntry[] = []

        if (p === INTERNAL_PROJECT) {
          entries = dayEntries.filter((e) => !e.project)
        } else {
          entries = dayEntries.filter((e) => {
            if (!e.project) {
              return false
            }
            return e.project.name === p
          })
        }
        const totalHours = entries.reduce((acc, e) => {
          if (!e.project) {
            return acc + 8
          } else {
            return acc + e.hours
          }
        }, 0)
        return { entries, totalHours, projectName: p }
      })
      acc.push({ label: day, projects, totalHours, modes })
      return acc
    }, [] as DateCluster[])
  }

  const getSummary = (): Summary => {
    const dateCluster = getDateCluster()
    const totalHours = dateCluster.reduce((acc, c) => acc + c.totalHours, 0)
    const projects = getUniqueProjects().map((p) => {
      const totalHours = dateCluster.reduce((acc, c) => {
        const project = c.projects.find((p2) => p2.projectName === p)
        return acc + (project ? project.totalHours : 0)
      }, 0)
      return totalHours
    })
    return { totalHours, projects }
  }

  if (!entries) {
    return <Loading loading={false} />
  } else if (!!entries && entries.length === 0) {
    return <div className="text-sm text-gray text-center">{t.fileSystem.toolbar.timetracker.entryList.empty}</div>
  } else if (!!entries) {
    return (
      <div className="flex flex-col w-full">
        <div className="overflow-auto border-x">
          <TableHeader projects={getUniqueProjects()} />
          <div className="divide-y min-w-full w-fit">
            {getDateCluster().map((c) => (
              <TableEntry cluster={c} key={c.label} pull={pull} />
            ))}
          </div>
          <TableFooter summary={getSummary()} />
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default EntryList

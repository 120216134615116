import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import { useState } from 'react'
import { s3Upload } from '@think-internet/zeus-frontend-package/fetch/fetch'
import { File as FileSystemFile } from '../../../../../types/FileSystem'
import useFileSystem from '../../useFileSystem'

type Response = {
  key: string
  presignedURL: string
}

export enum FileUploadStatus {
  READY,
  UPLOADING,
}

export type FileUploadState = {
  total?: number
  remaining?: number
  progress?: number
  workingOn?: string
  status: FileUploadStatus
}

type UseFileUpload = {
  fileUpload: (prefix: string, files: File[]) => Promise<FileSystemFile[]>
  state: FileUploadState
}

const useFileUpload = (): UseFileUpload => {
  const { fileSystem } = useFileSystem()
  const [state, setState] = useState<FileUploadState>({ status: FileUploadStatus.READY })
  const addFiles = functional.use(Routes.FILE_SYSTEM_ADD_FILES)
  const getUploadPresignedURL = functional.use(Routes.FILE_SYSTEM_GET_PRESIGNED_UPLOAD_URL)

  const updateProgress = (progress: number) => {
    setState((state) => ({
      ...state,
      progress,
    }))
  }

  const reduceRemaining = () => {
    setState((state) => ({
      ...state,
      remaining: state.remaining - 1,
    }))
  }

  const workingOn = (name: string) => {
    setState((state) => ({
      ...state,
      workingOn: name,
    }))
  }

  const resetState = () => {
    setState((state) => ({
      ...state,
      progress: 0,
      status: FileUploadStatus.READY,
    }))
  }

  const fileUpload = async (prefix: string, files: File[]) => {
    setState({
      total: files.length,
      remaining: files.length,
      progress: 0,
      status: FileUploadStatus.UPLOADING,
    })
    const keyList: string[] = []
    for (const file of files) {
      workingOn(file.name)
      const response: Response = await getUploadPresignedURL({
        prefix,
        name: file.name,
        mimeType: file.type,
      })
      if (!response) continue
      const { key, presignedURL } = response

      const uploadResult = await s3Upload(presignedURL, file, updateProgress)
      if (!uploadResult) continue
      reduceRemaining()
      keyList.push(key)
    }
    const createdFiles: FileSystemFile[] = await addFiles({ projectUUID: fileSystem.activeProjectUUID, keyList })
    resetState()
    return createdFiles
  }

  return {
    fileUpload,
    state,
  }
}

export default useFileUpload

import useProjects from '../../hooks/useContext/useProjects'
import Dropdown from '../Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useEffect, useState } from 'react'
import { Project } from '../../../types/Project'

type Props = {
  projectUUID: string
  value: string
  onChange: (value: string) => void
  required?: boolean
  disableLabel?: boolean
  contentClassName?: string
}

const ProjectAreaSelector: React.FC<Props> = ({ projectUUID, value, onChange, required = false, disableLabel = false, contentClassName = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [projects] = useProjects()
  const [project, setProject] = useState<Project>(null)

  useEffect(() => {
    if (!!projects && !!projectUUID && (!project || project.uuid !== projectUUID)) {
      const project = projects.find((p) => p.uuid === projectUUID)
      if (project) {
        setProject(project)
      }
    }
  }, [projects, project, projectUUID])

  if (!project || !Array.isArray(project.areas) || project.areas.length === 0) return null
  return (
    <Dropdown
      emptySelection="-"
      showEmptySelection
      label={!disableLabel && t.UI.projectAreaSelector.label}
      required={required}
      items={project.areas.map((a) => ({ label: a, value: a }))}
      value={value}
      onChange={onChange}
      contentClassName={contentClassName}
    />
  )
}

export default ProjectAreaSelector

import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Cell from './Cell'

const TableHeader: React.FC<{ projects: string[] }> = ({ projects }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="flex items-center divide-x">
      <Cell className="bg-background border-b-2 w-[50px] font-bold px-1">{t.timetracking.properties.day}</Cell>
      <Cell className="bg-background border-b-2 w-[100px] font-bold px-1">{t.timetracking.properties.date}</Cell>
      <Cell className="bg-background border-b-2 w-[100px] font-bold px-1">{t.timetracking.properties.mode}</Cell>
      <Cell className="bg-background border-b-2 w-[150px] font-bold px-1">{t.timetracking.properties.totalHours}</Cell>
      {projects.map((p) => (
        <div key={p} className="bg-background w-[300px] shrink-0">
          <Cell className="font-bold">{p}</Cell>
          <div className="flex divide-x border-t border-b-2">
            <Cell className="w-1/5">{t.timetracking.properties.hoursShort}</Cell>
            <Cell className="w-4/5">{t.timetracking.properties.description}</Cell>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TableHeader

import { Member } from './Member'
import { Project } from './Project'

export type TimetrackerMember = Pick<Member, 'uuid' | 'firstname' | 'lastname'>
export type TimetrackerProject = Pick<Project, 'uuid' | 'name'>

export enum TimetrackerEntryMode {
  ARBEITSTAG = 'ARBEITSTAG',
  FEIERTAG = 'FEIERTAG',
  KRANK = 'KRANK',
  URLAUB = 'URLAUB',
  FORTBILDUNGSTAG = 'FORTBILDUNGSTAG',
}

export type TimetrackerEntry = {
  uuid?: string
  projectUUID: string
  memberUUID?: string
  mode?: string
  area?: string
  description: string
  hours: number
  date: Date
  notBillable?: boolean
  isMeeting?: boolean

  // temp properties used for data transfer
  additionalMemberUUIDList?: string[]

  // dynamic properties
  member?: TimetrackerMember
  project?: TimetrackerProject
  number?: number
}

export type TimetrackerFilter = {
  projectUUID?: string
  projectArea?: string
  memberUUID?: string
  mode?: string
  dateRange?: {
    startDate: Date | null
    endDate: Date | null
  }
  includeNotBillable?: boolean
  includeIsMeeting?: boolean
  onlyMeetings?: boolean
}

export type BudgetTracker = {
  total: number
  used: number
  remaining: number
  usedPercentage: number
}

export type TimetrackerSummary = {
  project: Project
  totalHours: number
  entryAmount: number
  areaHours: { area: string; hours: number }[]
  memberHours: { name: string; hours: number }[]
  budgetTracker: BudgetTracker
}

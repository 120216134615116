import React, { useState } from 'react'
import { useDraggable, useDroppable } from '@dnd-kit/core'
import { Folder, Item as ItemType, ItemType as Type } from '../../../../../../types/FileSystem'
import { ItemWrapper, RowWrapper } from '../../Elements'
import prettyBytes from 'pretty-bytes'
import { getPrettyDateTime } from '../../../../../../utility'
import useDownloadFile from '../../ContextMenu/hooks/useDownloadFile'
import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import useGetFolderOptions from '../../ContextMenu/useGetFolderOptions'
import useGetFileOptions from '../../ContextMenu/useGetFileOptions'
import GetFileIcon from '../../../../../UI/GetFileIcon/GetFileIcon'
import useFileSystem from '../../../useFileSystem'

const DraggableItem = ({ item, children, onClick }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item.uuid,
  })

  // Simple style to indicate dragging and transform
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
  }

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes} onClick={onClick}>
      {children}
    </div>
  )
}

function DroppableFolder({ item, children }) {
  const { isOver, setNodeRef } = useDroppable({
    id: item.uuid,
  })

  const style = {
    backgroundColor: isOver ? '#111e3629' : '',
  }

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  )
}

type Props = {
  item: ItemType
  inMoveState?: boolean
}

const Item: React.FC<Props> = ({ item, inMoveState = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const getFolderOptions = useGetFolderOptions()
  const getFileOptions = useGetFileOptions()
  const [clickTimeout, setClickTimeout] = useState(null)
  const downloadFile = useDownloadFile()
  const { changeDirectory, setSelectedItem, fileSystem, toggleFilePreview } = useFileSystem()

  const isActive = fileSystem.activeSelectedItem?.uuid === item.uuid

  const onClick = () => {
    if (inMoveState) return
    if (clickTimeout) {
      // If a timeout already exists, clear it and treat this as a double-click
      clearTimeout(clickTimeout)
      setClickTimeout(null)

      if (item.type === Type.FOLDER) {
        handleFolderDoubleClick()
      } else {
        handleFileDoubleClick()
      }
    } else {
      setSelectedItem(item)
      // Otherwise, set a timeout and treat it as a single click for now
      const timeout = setTimeout(() => {
        if (item.type === Type.FOLDER) {
          handleFolderSingleClick()
        } else {
          handleFileSingleClick()
        }
        setClickTimeout(null)
      }, 300) // 300ms delay to check for double-click
      setClickTimeout(timeout)
    }
  }

  const handleFileSingleClick = () => {
    toggleFilePreview(item)
  }

  const handleFileDoubleClick = downloadFile(item)

  const handleFolderSingleClick = () => {}

  const handleFolderDoubleClick = () => {
    changeDirectory(item as Folder)
  }

  const getSize = () => {
    if (item.type === Type.FOLDER) {
      if (item.size === 1) return `${item.size} ${t.fileSystem.itemTable.folderSizeSuffixSingle}`
      return `${item.size} ${t.fileSystem.itemTable.folderSizeSuffixMultiple}`
    }
    return prettyBytes(item.size, {
      locale: true,
    })
  }

  const getDate = () => {
    if (item.type === Type.FOLDER) return getPrettyDateTime(item.createdOn)
    return getPrettyDateTime(item.updatedDate)
  }

  const getContextOptions = () => {
    if (item.type === Type.FOLDER) {
      return getFolderOptions(item)
    } else {
      return getFileOptions(item)
    }
  }

  const CoreItem = () => (
    <RowWrapper className={`${isActive ? 'bg-lightGray' : ''} group`} contextOptions={getContextOptions()}>
      <ItemWrapper
        small={false}
        className={inMoveState ? 'cursor-wait' : 'group-hover:underline'}
        Icon={<GetFileIcon filename={item.name} isFolder={item.type === Type.FOLDER} />}
      >
        <span className={inMoveState && 'text-lightGray'}>{item.name}</span>
      </ItemWrapper>
      <ItemWrapper>{getSize()}</ItemWrapper>
      <ItemWrapper>{getDate()}</ItemWrapper>
    </RowWrapper>
  )

  if (inMoveState) {
    return <CoreItem />
  }

  if (item.type === Type.FOLDER) {
    return (
      <DroppableFolder item={item}>
        <DraggableItem item={item} onClick={onClick}>
          <CoreItem />
        </DraggableItem>
      </DroppableFolder>
    )
  }
  return (
    <DraggableItem item={item} onClick={onClick}>
      <CoreItem />
    </DraggableItem>
  )
}

export default Item

import { Access } from '../../../types/Access'
import useMemberContext from '../../hooks/useContext/useMemberContext'

type Props = {
  children: React.ReactNode
  permission?: keyof Access
  fallback?: React.ReactNode
}

const HasPermission: React.FC<Props> = ({ children, permission, fallback }) => {
  const [context] = useMemberContext()

  if (!permission) return <>{children}</>

  const hasPermission = () => context?.permissions[permission]

  if (!context) return <></>
  if (!hasPermission()) return <>{fallback}</>
  return <>{children}</>
}

export default HasPermission

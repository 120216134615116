import { useState } from 'react'
import { ContextOption } from './ContextMenu'
import Modal from '../../../../UI/Modal/Modal'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import HasPermission from '../../../HasPermission/HasPermission'
import Input from '../../../../UI/Input/Input'
import useRenameFile from './hooks/useRenameFile'

export const ContextMenuOption: React.FC<{ option: ContextOption; clickCallback: () => void }> = ({ option, clickCallback }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const t = useSelector((s) => s[props.TRANSLATION])
  const { name, set, submit } = useRenameFile(option.rename)

  const onClick = () => {
    if (option.confirm) {
      setShowConfirmation(true)
    } else if (option.rename) {
      setShowRenameModal(true)
    } else {
      option.onClick()
      clickCallback()
    }
  }

  const confirm = () => {
    option.onClick()
    setShowConfirmation(false)
    clickCallback()
  }

  const confirmRename = () => {
    submit()
    setShowRenameModal(false)
    clickCallback()
  }

  const close = () => {
    setShowConfirmation(false)
    setShowConfirmation(false)
    clickCallback()
  }
  const content = (
    <div className="w-fit min-w-48" onClick={onClick}>
      <div className="flex hover:bg-lightGray items-center gap-2 px-2 py-1 cursor-pointer">
        <option.Icon className="h-4 w-4" />
        {option.name}
      </div>
    </div>
  )

  return (
    <>
      {option.permission ? <HasPermission permission={option.permission}>{content}</HasPermission> : content}
      <Modal title={t.fileSystem.itemTable.contextMenu.confirm.title} onClose={close} show={showConfirmation} onConfirm={confirm}>
        {option.confirm}
      </Modal>
      <Modal title={t.fileSystem.itemTable.contextMenu.rename.title} onClose={close} show={showRenameModal} onConfirm={confirmRename}>
        <Input value={name} onChange={set} />
      </Modal>
    </>
  )
}

export default ContextMenuOption

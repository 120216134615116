export enum StatusType {
  GOOD = 'GOOD',
  MEDIUM = 'MEDIUM',
  BAD = 'BAD',
}

const Status: React.FC<{ status: StatusType }> = ({ status }) => {
  if (status === StatusType.GOOD) {
    return <div className="bg-green w-4 h-4 rounded-full" />
  } else if (status === StatusType.MEDIUM) {
    return <div className="bg-yellowDefault w-4 h-4 rounded-full" />
  } else if (status === StatusType.BAD) {
    return <div className="bg-red w-4 h-4 rounded-full" />
  } else {
    return null
  }
}

export default Status

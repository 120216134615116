import { useSelector } from 'react-redux'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import DateRangePicker from '../../../UI/DateRangePicker/DateRangePicker'
import MemberSelector from '../../../UI/MemberSelector/MemberSelector'
import ProjectSelector from '../../../UI/ProjectSelector/ProjectSelector'
import props from '../../../../redux/props'
import { TimetrackerFilter } from '../../../../types/Timetracker'
import ProjectAreaSelector from '../../../UI/ProjectAreaSelector/ProjectAreaSelector'
import TimetrackerEntryModeSelector from '../../../UI/TimetrackerEntryModeSelector/TimetrackerEntryModeSelector'
import HasPermission from '../../HasPermission/HasPermission'

type Props = {
  disableProjectSelection?: boolean
  disableMemberSelection?: boolean
  filterConfig: TimetrackerFilter
  enableWeekSelection?: boolean
  updateFilterConfig: (key: keyof TimetrackerFilter) => (value: any) => void
}

const FilterCore: React.FC<Props> = ({
  disableProjectSelection = false,
  disableMemberSelection = false,
  filterConfig,
  updateFilterConfig,
  enableWeekSelection = false,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="flex flex-col gap-2">
      <TimetrackerEntryModeSelector showEmptySelection value={filterConfig.mode} onChange={updateFilterConfig('mode')} />
      {!disableProjectSelection && <ProjectSelector value={filterConfig.projectUUID} onChange={updateFilterConfig('projectUUID')} />}
      {(disableProjectSelection || !!filterConfig.projectUUID) && (
        <ProjectAreaSelector projectUUID={filterConfig.projectUUID} value={filterConfig.projectArea} onChange={updateFilterConfig('projectArea')} />
      )}
      {!disableMemberSelection && <MemberSelector value={filterConfig.memberUUID} onChange={updateFilterConfig('memberUUID')} />}
      <DateRangePicker enableWeekSelection={enableWeekSelection} value={filterConfig.dateRange} onChange={updateFilterConfig('dateRange')} />
      <Checkbox
        label={t.timetracking.filter.includeIsMeeting}
        checked={filterConfig.includeIsMeeting}
        onChange={updateFilterConfig('includeIsMeeting')}
      />
      <HasPermission permission="timetackerAccess">
        <Checkbox
          label={t.timetracking.filter.includeNotBillable}
          checked={filterConfig.includeNotBillable}
          onChange={updateFilterConfig('includeNotBillable')}
        />
      </HasPermission>
      <Checkbox label={t.timetracking.filter.onlyMeetings} checked={filterConfig.onlyMeetings} onChange={updateFilterConfig('onlyMeetings')} />
    </div>
  )
}

export default FilterCore

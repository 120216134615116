import Dropdown from '../Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { TimetrackerEntryMode } from '../../../types/Timetracker'

type Props = {
  value: string | string[]
  onChange: (value: string | string[]) => void
  required?: boolean
  multiple?: boolean
  disableLabel?: boolean
  showEmptySelection?: boolean
}

const TimetrackerEntryModeSelector: React.FC<Props> = ({
  value,
  onChange,
  multiple = false,
  required = false,
  disableLabel = false,
  showEmptySelection = false,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getItems = () => {
    return Object.values(TimetrackerEntryMode).map((m) => ({ label: t.UI.timetrackerEntryModeSelector.modes[m], value: m }))
  }

  return (
    <Dropdown
      label={!disableLabel && t.UI.timetrackerEntryModeSelector.label}
      placeholder={!!disableLabel && t.UI.timetrackerEntryModeSelector.label}
      showEmptySelection={showEmptySelection}
      emptySelection="-"
      required={required}
      items={getItems()}
      value={value}
      onChange={onChange}
      multiple={multiple}
      className={!!disableLabel && '!w-fit shrink-0'}
    />
  )
}

export default TimetrackerEntryModeSelector

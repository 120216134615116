import { Item } from '../types/FileSystem'
import { FileSystemStructureType } from '../types/FileSystemStructure/FileSystemStructure'
import { formatNumber } from '../utility'

export const translation = {
  projectName: 'LIGNE - L.I.S.A.',

  header: {
    navigation: {
      default: [
        {
          name: 'Anmelden',
          link: '/',
        },
      ],
      member: [
        {
          name: 'Projektverwaltung',
          link: '/dashboard/projects',
          requiredPermission: 'projectAccess',
        },
        {
          name: 'Dateiverzeichnis',
          link: '/dashboard/files',
          requiredPermission: 'fileAccess',
        },
        {
          name: 'Zeiterfassung',
          link: '/dashboard/timetracking',
          requiredPermission: 'timetackerAccess',
        },
        {
          name: 'Kontakte',
          link: '/dashboard/contact',
          requiredPermission: 'contactAccess',
        },
        {
          name: 'Protokoll',
          link: '/dashboard/protocol',
          requiredPermission: 'protocolAccess',
        },
        {
          name: 'Einstellungen',
          link: '/dashboard/settings',
          requiredPermission: 'settingsAccess',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  dashboard: {
    title: 'Dashboard',
  },

  project: {
    title: 'Projekte',
    list: {
      isArchived: 'Dieses Projekt ist archiviert',
    },
    create: {
      title: 'Projekt anlegen',
      namePlaceholder: 'Name',
      mailPlaceholder: 'Projekt E-Mail',
      mailSignaturePlaceholder: 'E-Mail Signatur',
      cta: 'Projekt anlegen',
      success: 'Projekt erstellt',
      error: 'Projekt konnte nicht erstellt werden',
      areas: {
        title: 'Projektbereiche',
      },
      fileSystemStructure: {
        typeSelection: 'Dateistruktur wählen',
        [FileSystemStructureType.STANDARD]: 'Standard',
      },
    },
    update: {
      titlePrefix: 'Projekt - ',
      success: 'Projekt wurde erfolgreich gespeichert',
      error: 'Projekt konnte nicht gespeichert werden',
      selectContact: 'Projektbeteiligte auswählen',
      selectMember: 'Projektverantwortliche auswählen',
    },
    remove: {
      error: 'Projekt konnte nicht gelöscht werden',
    },
    properties: {
      name: 'Name',
      isArchived: 'Archiviert',
      isArchivedHint: 'Archivierte Projekte werden nicht mehr im Dateiverzeichnis, der Projektübersicht und der Zeiterfassung angezeigt.',
      mail: 'Projekt E-Mail',
      mailSignature: 'Projekt E-Mail Signatur',
      areas: {
        title: 'Projektbereiche',
      },
    },
    budget: {
      label: 'Budgetverwaltung',
      total: 'Gesamtbudget',
      groups: 'Personengruppen',
      group: {
        name: 'Name',
        hourlyRate: 'Stundensatz',
        add: 'Gruppe hinzufügen',
        edit: 'Gruppe bearbeiten',
        selectMember: 'Mitarbeiter wählen',
        hourlyRateSuffix: ' p. Std.',
        memberSuffix: ' MA',
      },
    },
  },

  fileSystem: {
    sidebar: {
      companySelection: {
        title: 'Unternehmen',
      },
    },
    itemTable: {
      header: {
        name: 'Name',
        size: 'Größe',
        updatedDate: 'Geändert am',
      },
      folderSizeSuffixSingle: ' Element',
      folderSizeSuffixMultiple: ' Elemente',
      move: {
        error: 'Inhalt konnte nicht verschoben werden',
      },
      contextMenu: {
        confirm: {
          title: 'Bestätigung',
        },
        rename: {
          title: 'Umbenennen',
        },
        clipboard: {
          copy: 'Inhalt kopiert',
          cut: 'Inhalt ausgeschnitten',
          paste: 'Inhalt eingefügt',
        },
        folder: {
          copy: {
            cta: 'Kopieren',
          },
          cut: {
            cta: 'Ausschneiden',
          },
          paste: {
            cta: 'Einfügen',
            error: 'Inhalt konnte nicht eingefügt werden',
          },
          rename: {
            cta: 'Umbenennen',
            error: 'Ordner konnte nicht umbenannt werden',
          },
          remove: {
            cta: 'Löschen',
            confirm: (item: Item) => `Möchtest du den Ordner "${item.name}" wirklich löschen?`,
            success: 'Ordner wurde erfolgreich gelöscht',
            error: 'Ordner konnte nicht gelöscht werden',
          },
        },
        file: {
          copy: {
            cta: 'Kopieren',
          },
          cut: {
            cta: 'Ausschneiden',
          },
          paste: {
            cta: 'Einfügen',
            error: 'Inhalt konnte nicht eingefügt werden',
          },
          rename: {
            cta: 'Umbenennen',
            error: 'Datei konnte nicht umbenannt werden',
          },
          remove: {
            cta: 'Löschen',
            confirm: (item: Item) => `Möchtest du die Datei "${item.name}" wirklich löschen?`,
            success: 'Datei wurde erfolgreich gelöscht',
            error: 'Datei konnte nicht gelöscht werden',
          },
          download: {
            cta: 'Herunterladen',
            error: 'Datei konnte nicht heruntergeladen werden',
          },
        },
      },
    },
    toolbar: {
      runInitialization: {
        cta: 'Initialisieren',
        success: 'Initialisierung erfolgreich',
        error: 'Initialisierung fehlgeschlagen',
      },
      createFolder: {
        title: 'Ordner anlegen',
        placeholder: 'Name',
        cta: 'Ordner anlegen',
        success: 'Ordner erstellt',
        error: 'Ordner konnte nicht erstellt werden',
      },
      uploadFile: {
        title: 'Dateien hochladen',
        cta: 'Dateien hochladen',
        success: 'Dateien wurden erfolgreich hochgeladen',
        error: 'Dateien konnten nicht hochgeladen werden',
      },
      timetracker: {
        title: 'Zeiten erfassen',
        cta: 'Zeiten erfassen',
        success: 'Zeiten wurden erfolgreich erfasst',
        error: 'Zeiten konnten nicht erfasst werden',
        description: 'Tätigkeit',
        hours: 'Stunden',
        date: 'Tätigkeitsdatum',
        notBillable: 'Nicht abrechenbar',
        isMeeting: 'Besprechung',
        selectAdditionalMember: 'Zusätzliche Mitarbeiter auswählen (bei Bedarf)',
        showMemberTimetracking: 'Persönliche Zeiterfassung anzeigen',
        memberEntriesOfProject: 'Deine Einträge für dieses Projekt:',
        entryList: {
          pending: 'Zuerst das entsprechende Projekt auswählen',
          areaPrefix: 'Projektbereich: ',
          empty: 'Keine Einträge vorhanden',
        },
      },
    },
  },

  timetracking: {
    title: 'Zeiterfassung',
    month: 'Monatseinschränkung',
    contactExportCTA: 'Projektbeteiligtenliste exportieren',
    summary: {
      generic: 'Allgemein',
      totalHours: 'Gesamtstunden: ',
      totalEntries: 'Einträge: ',
      byEmployee: 'Stunden je Mitarbeiter',
      byArea: 'Stunden je Projektbereich',
      openProject: 'Öffnen',
      budgetTracker: {
        title: 'Budgetübersicht',
        total: 'Gesamtbudget',
        used: 'Verbraucht',
        remaining: 'Verbleibend',
        noBudget: 'Kein Budget hinterlegt',
      },
    },
    update: {
      title: 'Eintrag bearbeiten',
      success: 'Eintrag wurde erfolgreich aktualisiert',
      error: 'Eintrag konnte nicht aktualisiert werden',
    },
    remove: {
      error: 'Eintrag konnte nicht gelöscht werden',
      success: 'Eintrag wurde erfolgreich gelöscht',
    },
    filter: {
      cta: 'Filtern',
      modalTitle: 'Filter',
      includeIsMeeting: 'Besprechungen einschließen',
      includeNotBillable: 'Nicht abrechenbare Einträge einschließen',
      onlyMeetings: 'Nur Termine',
    },
    properties: {
      project: 'Projekt',
      name: 'Name',
      member: 'Mitarbeiter',
      firstname: 'Vorname',
      lastname: 'Nachname',
      area: 'Projektbereich',
      description: 'Tätigkeit',
      totalHours: 'Gesamtstunden',
      hours: 'Stunden',
      hoursShort: 'Std.',
      date: 'Datum',
      calendarWeek: 'Kalenderwoche',
      notBillable: 'Nicht abrechenbar',
      billable: 'Abrechenbar',
      isMeeting: 'Besprechung',
      laufendeNummer: 'Lfd. Nr.',
      mode: 'Zeitkonto',
      day: 'Tag',
      billableOptions: {
        yes: 'Ja',
        no: 'Nein',
      },
      subSumMonthLabel: (month: string, year: number, hours: number) =>
        `Zwischensumme Aufwand Monat ${month} ${year}: ${formatNumber(hours)} Stunden`,
      subSumYearLabel: (year: number, hours: number) => `Zwischensumme Aufwand Jahr ${year}: ${formatNumber(hours)} Stunden`,
    },
    error: {
      bulkUpdate: 'Einträge konnten nicht aktualisiert werden.',
    },
    success: {
      bulkUpdate: 'Einträge wurden erfolgreich aktualisiert.',
    },
  },

  contact: {
    title: 'Kontakte',
    search: 'Kontaktsuche',
    upsert: {
      title: {
        create: 'Kontakt anlegen',
        update: 'Kontakt aktualisieren',
      },
      cta: 'Kontakt anlegen',
      success: {
        create: 'Kontakt wurde erfolgreich gespeichert',
        update: 'Kontakt wurde erfolgreich aktualisiert',
        remove: 'Kontakt wurde erfolgreich gelöscht',
      },
      error: {
        save: 'Kontakt konnte nicht gespeichert werden',
        remove: 'Kontakt konnte nicht gelöscht werden',
      },
    },
    ansprechpartner: {
      upsert: {
        title: {
          create: 'Ansprechpartner anlegen',
          update: 'Ansprechpartner aktualisieren',
        },
      },
    },
    properties: {
      gewerk: 'Gewerk',
      firma: 'Firma',
      ansprechpartnerList: 'Ansprechpartner',
      name: 'Name',
      vorname: 'Vorname',
      nachname: 'Nachname',
      kuerzel: 'Kürzel',
      position: 'Position',
      adresse: 'Adresse',
      telefon: 'Telefon',
      mobil: 'Mobil',
      mail: 'E-Mail',
      projects: 'Projekte',
      projectUUIDList: 'Projekte',
      bemerkung: 'Bemerkung',
      bewertung: 'Bewertung',
      website: 'Webseite',
      isSensitive: 'Sensibler Kontakt',
      bewertungItems: {
        GOOD: 'Gut',
        MEDIUM: 'Mittel',
        BAD: 'Schlecht',
      },
    },
  },

  protocol: {
    overview: {
      title: 'Besprechungsprotokolle',
    },
    create: {
      title: 'Protokoll erstellen',
      error: 'Protokoll konnte nicht angelegt werden',
      cta: 'Protokoll erstellen',
    },
    success: {
      remove: 'Protokoll wurde erfolgreich gelöscht',
      update: 'Protokoll wurde erfolgreich aktualisiert',
    },
    error: {
      remove: 'Protokoll konnte nicht gelöscht werden',
      update: 'Protokoll konnte nicht aktualisiert werden',
    },
    view: {
      titlePrefix: 'Besprechungsprotokoll Nr.: ',
      projectPrefix: 'Projekt: ',
      addThemaCTA: 'Thema hinzufügen',
      addThemaItemCTA: 'Punkt hinzufügen',
      save: 'Protokoll speichern',
      copyLastProtocol: 'Inhalte aus letztem Protokoll kopieren',
      copyLastProtocolText: 'Durch das Kopieren des letzten Protokolls werden alle Inhalte des letzten Protokolls in das aktuelle übernommen.',
    },
    properties: {
      number: 'Nr.',
      project: 'Projekt',
      mainTopic: 'Hauptthema',
      besprechungsort: 'Besprechungsort',
      date: 'Datum',
      startTime: 'Startzeit',
      endTime: 'Endzeit',
      teilnehmer: 'Teilnehmer',
      teilnehmerZusaetzlich: 'Zusätzliche Verteiler',
      teilnehmerMitarbeiter: 'Teilnehmende Mitarbeiter',
      notes: 'Notizen',
      teilnehmerProps: {
        vorname: 'Vorname',
        nachname: 'Nachname',
        kuerzel: 'Kürzel',
        firma: 'Firma',
        mail: 'E-Mail',
      },
      themen: 'Themen',
      themaProps: {
        title: 'Thema',
        laufendeNummer: 'lfd.Nr.',
        text: 'Thema',
        verantwortlich: 'Verantwortlich',
        deadline: 'Termin',
        status: 'Status',
        statusItems: {
          NEW: 'Neu',
          OPEN: 'Offen',
          DONE: 'Erledigt',
        },
        enableForExport: 'Exportieren',
      },
      attachments: 'Anhänge',
      attachmentProps: {
        empty: 'Keine Anhänge vorhanden',
      },
    },
  },

  pdfInterpreter: {
    upload: {
      label: 'PDF hochladen',
    },
  },

  login: {
    headline: 'Anmelden',
    cta: 'Anmelden',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  settings: {
    title: 'Einstellungen',
    memberManagement: {
      title: 'Mitarbeiter',
      upsert: {
        titleCreate: 'Mitarbeiter hinzufügen',
        titleUpdate: 'Mitarbeiter bearbeiten',
        cta: 'Mitarbeiter hinzufügen',
        success: 'Mitarbeiter wurde erfolgreich gespeichert',
        error: {
          upsert: 'Mitarbeiter konnte nicht gespeichert werden',
          remove: 'Mitarbeiter konnte nicht gelöscht werden',
          loading: 'Mitarbeiter konnten nicht geladen werden',
        },
        edit: 'Mitarbeiter bearbeiten',
        remove: {
          title: 'Mitarbeiter löschen',
          hint: 'Bist du sicher, dass du diesen Mitarbeiter löschen möchten?',
          error: 'Mitarbeiter konnte nicht gelöscht werden',
        },
      },
      companyAssignment: {
        title: 'Unternehmenszuweisung',
      },
    },
    accessManagement: {
      title: 'Berechtigungen',
      update: {
        success: 'Berechtigung wurde erfolgreich gespeichert',
        cta: 'Speichern',
        error: {
          upsert: 'Berechtigung konnte nicht gespeichert werden',
          loading: 'Berechtigungen konnten nicht geladen werden',
        },
      },
      level: {
        title: 'Berechtigung',
        ADMIN: 'Geschäftsführung',
        ADVANCED: 'Office',
        USER: 'Benutzer',
      },
      properties: {
        projectAccess: 'Projektverwaltung',
        createProject: 'Projekte anlegen',
        fileAccess: 'Dateiverzeichnis',
        uploadFiles: 'Dateien hochladen',
        deleteFiles: 'Dateien löschen',
        moveFiles: 'Dateien verschieben',
        createFolders: 'Ordner erstellen',
        deleteFolders: 'Ordner löschen',
        moveFolders: 'Ordner verschieben',
        shareFiles: 'Dateien teilen',
        shareFolders: 'Ordner teilen',
        settingsAccess: 'Einstellungen',
        timetackerAccess: 'Zeiterfassung-Auswertung',
        timetackerCreateEntry: 'Zeiterfassung Einträge erstellen',
        timetackerUpdateEntry: 'Zeiterfassung Einträge bearbeiten',
        timetackerRemoveEntry: 'Zeiterfassung Einträge löschen',
        contactAccess: 'Kontakte',
        createContact: 'Kontakte anlegen',
        manageSensitiveContactData: 'Sensible Kontaktdaten verwalten',
        protocolAccess: 'Protokolle einsehen',
        createProtocol: 'Protokolle erstellen',
        sendProtocol: 'Protokolle verschicken',
        budgetManagement: 'Budgetverwaltung',
      },
    },
    companyManagement: {
      title: 'Unternehmen',
      upsert: {
        titleCreate: 'Unternehmen hinzufügen',
        titleUpdate: 'Unternehmen bearbeiten',
        cta: 'Unternehmen hinzufügen',
        success: 'Unternehmen wurde erfolgreich gespeichert',
        error: {
          upsert: 'Unternehmen konnte nicht gespeichert werden',
          remove: 'Unternehmen konnte nicht gelöscht werden',
          loading: 'Unternehmen konnten nicht geladen werden',
        },
        edit: 'Unternehmen bearbeiten',
        remove: {
          title: 'Unternehmen löschen',
          hint: 'Bist du sicher, dass du dieses Unternehmen löschen möchten?',
          error: 'Unternehmen konnte nicht gelöscht werden',
        },
      },
    },
  },

  footer: {
    links: 'Links',
    legalLinks: [
      { name: 'Impressum', link: 'https://www.ligne.de/impressum' },
      { name: 'Datenschutz', link: 'https://www.ligne.de/datenschutz' },
    ],
  },

  generic: {
    company: {
      name: 'LIGNE ARCHITEKTEN',
      street: 'Wilhelm-Kabus-Str. 36, Haus 9',
      city: '10829 Berlin',
      mail: 'info@ligne.de',
    },
    select: 'Auswählen',
    search: 'Suche',
    remove: 'Löschen',
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    settings: 'Einstellungen',
    hourPrefix: 'Stunden: ',
    datePrefix: 'Datum: ',
    calendarWeekPrefix: 'KW: ',
    hourSuffix: ' Stunden',
    squareMeterSuffisx: ' m²',
    equals: ' = ',
    timestampPrefix: 'Erstellt am: ',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    personType: 'Art',
    personTypePlaceholder: 'Beirat, Geschäftsführer, ...',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    identifier: 'Benutzername',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
    save: 'Speichern',
    months: {
      0: 'Januar',
      1: 'Februar',
      2: 'März',
      3: 'April',
      4: 'Mai',
      5: 'Juni',
      6: 'Juli',
      7: 'August',
      8: 'September',
      9: 'Oktober',
      10: 'November',
      11: 'Dezember',
    },
  },

  UI: {
    timetrackerEntryModeSelector: {
      label: 'Zeitkonto',
      modes: {
        ARBEITSTAG: 'Arbeitstag',
        FEIERTAG: 'Feiertag',
        KRANK: 'Krankheitstag',
        URLAUB: 'Urlaubstag',
        FORTBILDUNGSTAG: 'Fortbildungstag',
      },
    },
    removeConfirmation: {
      title: 'Bestätigung',
      text: (itemName?: string) => (!!itemName ? `Möchtest du "${itemName}" wirklich löschen?` : 'Möchten du den gewählten Inhalt wirklich löschen?'),
    },
    copyConfirmation: {
      title: 'Kopieren',
      text: (itemName: string) => `${itemName}-Kopie umbenennen zu:`,
      initNameSuffix: '-Kopie',
    },
    contactExport: {
      cta: 'Exportieren',
      title: 'Exportieren',
      chooseFormat: 'Format wählen',
    },
    publicFileUpload: {
      cta: 'Datei hochladen',
      title: 'Dateien hochladen',
      success: 'Dateien wurden erfolgreich hochgeladen',
      error: 'Dateien konnten nicht hochgeladen werden',
      download: {
        error: 'Datei konnte nicht heruntergeladen werden',
      },
    },
    dateRangePicker: {
      label: 'Zeitraum wählen',
      labelSingleSelect: 'Tag der KW wählen',
    },
    sendViaMail: {
      cta: 'Versenden',
      title: 'Versenden via E-Mail',
      testReceiver: 'Test-Empfänger',
      success: 'E-Mail wurde erfolgreich versendet',
      error: 'E-Mail konnte nicht versendet werden',
    },
    export: {
      cta: 'Exportieren',
      title: 'Exportieren',
      type: {
        PDF: 'PDF',
        CSV: 'CSV',
      },
      error: 'Export fehlgeschlagen',
      chooseFormat: 'Format wählen',
      chooseProps: 'Eigenschaften wählen',
    },
    gewerkSelector: {
      label: 'Gewerk wählen',
    },
    memberSelector: {
      label: 'Mitarbeiterauswahl',
    },
    contactSelector: {
      label: 'Kontaktauswahl',
    },
    contactAnsprechpartnerSelector: {
      label: 'Ansprechpartner wählen',
    },
    customerSelector: {
      label: 'Kundenauswahl',
    },
    projectSelector: {
      label: 'Projekt wählen',
    },
    companySelector: {
      label: 'Unternehmen wählen',
    },
    projectAreaSelector: {
      label: 'Projektbereich wählen',
    },
    contactPersonManager: {
      title: 'Kontaktpersonen',
      addTitle: 'Kontaktperson hinzufügen',
      updateTitle: 'Kontaktperson bearbeiten',
      empty: 'Keine Kontaktpersonen vorhanden',
      add: 'hinzufügen',
    },
    organizationUnitManager: {
      title: 'Organisationseinheiten',
      addTitle: 'Organisationseinheit hinzufügen',
      employeeAmountSuffixShort: ' MA',
      employeeAmountSuffix: ' Mitarbeiter',
      updateTitle: 'Organisationseinheit bearbeiten',
      empty: 'Keine Organisationseinheiten vorhanden',
      add: 'hinzufügen',
    },
    simpleList: {
      search: 'Suche',
      resultAmountSuffixPlural: ' Ergebnisse',
      resultAmountSuffixSingular: ' Ergebnis',
      noData: 'Keine Daten vorhanden',
    },
    multiList: {
      search: 'Suche',
      resultAmountSuffix: ' Ergebnisse',
      noData: 'Keine Daten vorhanden',
      cta: 'Ausführen',
      cancelCTA: 'Abbrechen',
      option: {
        EXPORT: 'Export',
        PROCESS: 'Verarbeiten',
        STOP: 'Stoppen',
      },
      export: {
        title: 'Export',
        identifier: 'Kennung',
      },
      error: 'Aktion konnte nicht ausgeführt werden',
    },
    csvImport: {
      title: 'Import',
      startCTA: 'Import starten',
      loading: 'Daten werden imporiert...',
      error: 'Der Import ist fehlgeschlagen.',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
      search: 'Suche',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Maximale Dateigröße überschritten',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'

import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import Input from '../../../../UI/Input/Input'
import { Mitarbeiter as MitarbeiterType, ProtocolEntry } from '../../../../../types/Protocol'
import { useEffect } from 'react'
import { TrashIcon } from '@heroicons/react/24/solid'
import useMember from '../../../../hooks/useContext/useMember'
import ModalChecklist from '../../../../UI/ModalChecklist/ModalChecklist'

type Props = {
  label: string
  protocolEntry: ProtocolEntry
  value: MitarbeiterType[]
  onChange: (value: MitarbeiterType[]) => void
}

const Teilnehmer: React.FC<Props> = ({ protocolEntry, label, value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [members] = useMember()

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([])
    }
  }, [value, onChange])

  const updateProp = (index: number, key: keyof MitarbeiterType) => (propValue: any) => {
    const newValue = [...value]
    newValue[index] = { ...newValue[index], [key]: propValue }
    onChange(newValue)
  }

  const resolveName = (uuid: string) => {
    const member = members.find((m) => m.uuid === uuid)
    return member ? `${member.firstname} ${member.lastname}` : ''
  }

  const getMail = () => {
    const projectMail = protocolEntry?.project?.mail
    return projectMail ? projectMail : ''
  }

  const getFirma = () => {
    const projectMail = protocolEntry?.company?.name
    return projectMail ? projectMail : ''
  }

  const internalOnChange = (uuidList: string[]) => {
    const selectedMembers = members.filter((m) => uuidList.includes(m.uuid))
    const existingMembers = value.map((v) => v.memberUUID)

    const updatedMembers: MitarbeiterType[] = selectedMembers.map((m) => {
      if (existingMembers.includes(m.uuid)) {
        const existingMember = value.find((v) => v.memberUUID === m.uuid)
        return {
          uuid: existingMember.memberUUID,
          memberUUID: existingMember.memberUUID,
          vorname: existingMember.vorname,
          nachname: existingMember.nachname,
          kuerzel: existingMember.kuerzel,
          mail: existingMember.mail,
          firma: existingMember.firma,
        }
      } else {
        return {
          uuid: m.uuid,
          memberUUID: m.uuid,
          vorname: m.firstname,
          nachname: m.lastname,
          kuerzel: '',
          mail: getMail(),
          firma: getFirma(),
        }
      }
    })
    onChange(updatedMembers)
  }

  const remove = (index: number) => () => {
    onChange(value.filter((_, i) => i !== index))
  }

  if (!value || !members) return null
  return (
    <div>
      <div className="font-bold">{label}</div>
      <div>
        <div className="flex gap-3">
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.vorname}</div>
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.nachname}</div>
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.kuerzel}</div>
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.firma}</div>
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.mail}</div>
          <div className="w-5"></div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {Array.isArray(value) &&
          value.map((v, i) => (
            <div className="flex gap-2 items-center" key={i}>
              <div className="w-1/5">
                <Input value={v.vorname} onChange={updateProp(i, 'vorname')} />
              </div>
              <div className="w-1/5">
                <Input value={v.nachname} onChange={updateProp(i, 'nachname')} />
              </div>
              <div className="w-1/5">
                <Input value={v.kuerzel} onChange={updateProp(i, 'kuerzel')} />
              </div>
              <div className="w-1/5">
                <Input value={v.firma} onChange={updateProp(i, 'firma')} />
              </div>
              <div className="w-1/5">
                <Input value={v.mail} onChange={updateProp(i, 'mail')} />
              </div>
              <div>
                <TrashIcon className="w-5 fill-gray hover:fill-red cursor-pointer" onClick={remove(i)} />
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-end mt-2">
        <ModalChecklist
          label={label}
          ctaLabel={t.generic.edit}
          items={members.map((m) => m.uuid)}
          value={value.map((v) => v.memberUUID)}
          onChange={internalOnChange}
          resolveName={resolveName}
          disableResultList
        />
      </div>
    </div>
  )
}

export default Teilnehmer

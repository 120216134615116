import { isNumber } from 'lodash'
import Input, { BaseInput } from './Input'
import CurrencyFormat from 'react-currency-format'

const Number: React.FC<BaseInput & { decimal?: boolean; suffix?: string; preserveZero?: boolean }> = ({
  value,
  onChange,
  decimal = false,
  suffix = '',
  preserveZero = false,
  ...params
}) => {
  const internalOnChange = (value: number) => {
    if (!!params.max && isNumber(params.max) && value > params.max) {
      onChange(params.max)
    } else {
      onChange(value)
    }
  }

  return (
    <CurrencyFormat
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      decimalScale={decimal ? 2 : 0}
      fixedDecimalScale={decimal}
      value={typeof value === 'number' && !isNaN(value) ? value : preserveZero ? 0 : ''}
      onValueChange={(v) => internalOnChange(v.floatValue)}
      returnRawChangeEvent
      textAlign={'right'}
      suffix={suffix && ` ${suffix}`}
      {...params}
      type="text"
    />
  )
}

export default Number

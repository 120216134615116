import { useSelector } from 'react-redux'
import { TimetrackerEntry } from '../../../../../../types/Timetracker'
import props from '../../../../../../redux/props'
import Modal from '../../../../../UI/Modal/Modal'
import { useState } from 'react'
import UpsertForm from '../../../Entry/UpsertForm'
import _ from 'lodash'
import HasPermission from '../../../../HasPermission/HasPermission'
import useTimetracker from '../../../../../hooks/useContext/useTimetracker'
import useToast, { Type } from '../../../../../hooks/useToast'
import RemoveConfirmation from '../../../../../UI/RemoveConfirmation/RemoveConfirmation'
import { TrashIcon } from '@heroicons/react/24/solid'

type Props = {
  entry: TimetrackerEntry
  pull: (projectUUID?: string) => void
  CustomTrigger?: React.ReactNode
}

const InlineEntryCustomizer: React.FC<Props> = ({ entry, pull, CustomTrigger }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<TimetrackerEntry>(_.cloneDeep(entry))
  const toast = useToast()
  const timetracker = useTimetracker()

  const toggle = () => setOpen(!open)

  const update = async () => {
    const status = await timetracker.update(data)
    if (status) {
      toggle()
      if (!!data.projectUUID) {
        pull(data.projectUUID)
      } else {
        pull()
      }
      toast(t.timetracking.update.success, Type.SUCCESS)
    } else {
      toast(t.timetracking.update.error)
    }
  }

  const set = (key: keyof TimetrackerEntry) => (value: any) => {
    if (key === 'projectUUID') {
      setData({ ...data, [key]: value, area: null })
      fetch(value)
    } else {
      setData({ ...data, [key]: value })
    }
  }

  const Core: React.FC<{ withTrigger?: boolean }> = ({ withTrigger }) => {
    return (
      <span
        onClick={withTrigger && toggle}
        className={`whitespace-pre-wrap ${withTrigger && 'cursor-pointer py-[2px] hover:bg-blue hover:text-white transition-all'}`}
      >
        {CustomTrigger ? CustomTrigger : !!entry.project ? entry.description : t.UI.timetrackerEntryModeSelector.modes[entry.mode]}
      </span>
    )
  }

  const RemoveActionButton: React.FC = () => {
    const remove = async () => {
      const status = await timetracker.remove(data.uuid)
      if (status) {
        toggle()
        if (!!data.projectUUID) {
          pull(data.projectUUID)
        } else {
          pull()
        }
        toast(t.timetracking.remove.success, Type.SUCCESS)
      } else {
        toast(t.timetracking.remove.error)
      }
    }
    return (
      <HasPermission permission="timetackerRemoveEntry">
        <RemoveConfirmation confirmationCallback={remove}>
          <TrashIcon className="fill-gray hover:fill-red w-5 cursor-pointer" />
        </RemoveConfirmation>
      </HasPermission>
    )
  }

  return (
    <>
      <HasPermission permission="timetackerUpdateEntry" fallback={<Core />}>
        <Core withTrigger />
      </HasPermission>
      <Modal onClose={toggle} show={open} onConfirm={update} title={t.timetracking.update.title} actionButton={<RemoveActionButton />}>
        <UpsertForm data={data} set={set} />
      </Modal>
    </>
  )
}

export default InlineEntryCustomizer

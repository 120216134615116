import { Company } from './Company'
import { PublicFile } from './Generic'
import { Project } from './Project'

export enum ThemaItemStatus {
  NEW = 'NEW',
  OPEN = 'OPEN',
  DONE = 'DONE',
}

export type ThemaItem = {
  text?: string
  verantwortlich?: string
  deadline?: string
  status?: ThemaItemStatus
  enableForExport?: boolean
}

export type Thema = {
  thema?: string
  items?: ThemaItem[]
}

export type Teilnehmer = {
  contactUUID?: string
  ansprechpartnerUUID?: string
  vorname?: string
  nachname?: string
  kuerzel?: string
  firma?: string
  mail?: string
}

export type Mitarbeiter = {
  memberUUID?: string
  vorname?: string
  nachname?: string
  kuerzel?: string
  firma?: string
  mail?: string
}

export type ProtocolEntry = {
  uuid?: string
  projectUUID?: string
  mainTopic?: string
  number?: number
  besprechungsort?: string
  date?: Date
  startTime?: Date
  endTime?: Date
  teilnehmer?: Teilnehmer[]
  teilnehmerZusaetzlich?: Teilnehmer[]
  teilnehmerMitarbeiter?: Mitarbeiter[]
  notes?: string
  themen?: Thema[]
  attachments?: PublicFile[]

  // dynamic properties
  project?: Project
  company?: Company
}

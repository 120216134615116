import { BudgetGroup } from '../../../../../types/Project'
import Modal from '../../../../UI/Modal/Modal'
import Button from '../../../../UI/Button/Button'
import Input from '../../../../UI/Input/Input'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { getUUID } from '../../../../../utility'
import MemberSelector from '../../../../UI/MemberSelector/MemberSelector'
import { PencilIcon } from '@heroicons/react/24/solid'
import Currency from '../../../../UI/Input/Currency'

type Props = {
  initGroup?: BudgetGroup
  onChange: (group: BudgetGroup) => void
  remove?: () => void
}

const Group: React.FC<Props> = ({ initGroup, onChange, remove }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [group, setGroup] = useState<BudgetGroup>(initGroup || null)

  useEffect(() => {
    if (!initGroup && !group) {
      setGroup({
        uuid: getUUID(),
        name: '',
        hourlyRate: 0,
        memberUUIDList: [],
      })
    }
  }, [initGroup, group])

  const set = (key: string) => (value: any) => {
    const newGroup = _.cloneDeep(group)
    newGroup[key] = value
    setGroup(newGroup)
  }

  const toggle = () => setOpen(!open)

  const confirm = () => {
    onChange(group)
    if (!initGroup) {
      setGroup(null)
    }
    toggle()
  }

  if (!group) return null
  return (
    <div>
      {!!initGroup ? (
        <div className="flex items-center justify-between">
          <div>{group.name}</div>
          <div>
            {group.hourlyRate}
            {t.project.budget.group.hourlyRateSuffix}
          </div>
          <div>
            {group.memberUUIDList.length}
            {t.project.budget.group.memberSuffix}
          </div>
          <PencilIcon className="w-5 fill-blue cursor-pointer" onClick={toggle} />
        </div>
      ) : (
        <Button onClick={toggle} text={t.generic.add} small />
      )}
      <Modal
        onRemove={remove}
        title={initGroup ? t.project.budget.group.edit : t.project.budget.group.add}
        noForm
        onClose={toggle}
        show={open}
        onConfirm={confirm}
      >
        <div className="flex flex-col gap-3">
          <Input label={t.project.budget.group.name} value={group.name} onChange={set('name')} />
          <Currency label={t.project.budget.group.hourlyRate} value={group.hourlyRate} onChange={set('hourlyRate')} />
          <MemberSelector multiple label={t.project.budget.group.selectMember} value={group.memberUUIDList} onChange={set('memberUUIDList')} />
          {/* <Button onClick={addGroup} text={t.generic.add} small /> */}
        </div>
      </Modal>
    </div>
  )
}

export default Group

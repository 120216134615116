import { formatNumber, getWeekDay, reversePrettyDate } from '../../../../../../utility'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { DateCluster } from '../EntryList'
import Cell from './Cell'
import InlineEntryCustomizer from './InlineEntryCustomizer'

type Props = {
  cluster: DateCluster
  pull: () => void
}

const TableEntry: React.FC<Props> = ({ cluster, pull }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div key={cluster.label} className="flex divide-x border-blue first:border-none">
      <Cell className="w-[50px] font-bold px-1">{getWeekDay(reversePrettyDate(cluster.label)).slice(0, 2)}</Cell>
      <Cell className="w-[100px] px-1">{cluster.label}</Cell>
      <Cell className="w-[100px] px-1 whitespace-pre-line">{cluster.modes.map((m) => t.UI.timetrackerEntryModeSelector.modes[m]).join(', ')}</Cell>
      <Cell className="w-[150px] px-1">{formatNumber(cluster.totalHours)}</Cell>
      {cluster.projects.map((p) => {
        return (
          <div key={p.projectName} className="w-[300px] text-sm shrink-0">
            <div className="flex divide-x h-full">
              <Cell className="w-1/5 font-bold">{formatNumber(p.totalHours)}</Cell>
              <Cell className="w-4/5">
                {p.entries.map((e, i) => (
                  <span>
                    <InlineEntryCustomizer key={e.uuid} entry={e} pull={pull} />
                    {p.entries.length - 1 > i ? ', ' : ''}
                  </span>
                ))}
              </Cell>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TableEntry

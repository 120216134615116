import { useSelector } from 'react-redux'
import FullHeightWrapper from '../../../UI/FullHeightWrapper/FullHeightWrapper'
import props from '../../../../redux/props'
import useProtocol from './useProtocol'
import { useParams } from 'react-router-dom'
import Loading from '../../../UI/Loading/Loading'
import EditInput from './EditInput'
import Container from '../../../UI/Container/Container'
import Themen from './Themen/Themen'
import { ProtocolEntry, ThemaItemStatus } from '../../../../types/Protocol'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import Teilnehmer from './Teilnehmer/Teilnehmer'
import Attachments from './Attachments/Attachments'
import Confirmation from '../../../UI/Confirmation/Confirmation'
import SendViaMail from './SendViaMail/SendViaMail'
import { ExportType } from '../../../../types/Export'
import Export from '../../../UI/Export/Export'
import SaveBar from '../../../UI/SaveBar/SaveBar'
import Textarea from '../../../UI/Textarea/Textarea'
import Mitarbeiter from './Mitarbeiter/Mitarbeiter'
import HasPermission from '../../HasPermission/HasPermission'

const Entry = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams()
  const [data, set, update] = useProtocol(uuid)
  const [lastProtocol, setLastProtocol] = useState<ProtocolEntry | false>(null)
  const getPreviousProtocol = functional.use(Routes.PROTOCOL_ENTRY_PREVIOUS_OF_PROJECT)

  useEffect(() => {
    const get = async () => {
      const entry = await getPreviousProtocol({ uuid })
      if (entry) {
        setLastProtocol(entry)
      } else {
        setLastProtocol(false)
      }
    }
    if (lastProtocol === null) {
      get()
    }
  }, [lastProtocol, getPreviousProtocol, setLastProtocol, uuid])

  const save = () => update(data)

  const copy = () => {
    if (!!lastProtocol) {
      update({
        ...data,
        besprechungsort: lastProtocol.besprechungsort,
        teilnehmer: lastProtocol.teilnehmer,
        teilnehmerZusaetzlich: lastProtocol.teilnehmerZusaetzlich,
        teilnehmerMitarbeiter: lastProtocol.teilnehmerMitarbeiter,
        notes: lastProtocol.notes,
        themen: lastProtocol.themen.map((t) => ({
          ...t,
          items: t.items
            .filter((t) => t.status !== ThemaItemStatus.DONE)
            .map((i) => ({
              ...i,
              status: i.status === ThemaItemStatus.NEW ? ThemaItemStatus.OPEN : i.status,
            })),
        })),
        attachments:
          Array.isArray(data.attachments) && data.attachments.length > 0
            ? [...data.attachments, ...lastProtocol.attachments]
            : lastProtocol.attachments,
      })
    }
  }

  return (
    <FullHeightWrapper>
      <Container>
        <div className="pt-10 pb-20">
          <Loading loading={data} />
          {!!data && (
            <div className="flex flex-col gap-5">
              <div className="flex justify-between items-center">
                <div className="font-bold text-xl text-blue">{`${t.protocol.view.titlePrefix}${data.number}`}</div>
                <div className="flex gap-3">
                  {!!lastProtocol && (
                    <Confirmation title={t.protocol.view.copyLastProtocol} text={t.protocol.view.copyLastProtocolText} callback={copy} />
                  )}
                  <Export exportTypes={[ExportType.PDF]} filter={{ uuid }} route={Routes.GENERATE_PROTOCOL_EXPORT} translationProp="protocol" />
                  <HasPermission permission="sendProtocol">
                    <SendViaMail uuid={uuid} />
                  </HasPermission>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex gap-2 mt-3 justify-between">
                  <div className="font-bold w-1/2">{t.protocol.view.projectPrefix}</div>
                  <div className="w-1/2">{data?.project?.name || '-'}</div>
                </div>
                <EditInput label={t.protocol.properties.mainTopic} initValue={data.mainTopic} onChange={set('mainTopic')} />
                <EditInput label={t.protocol.properties.besprechungsort} initValue={data.besprechungsort} onChange={set('besprechungsort')} />
                <EditInput type="date" label={t.protocol.properties.date} initValue={data.date} onChange={set('date')} />
                <EditInput type="time" label={t.protocol.properties.startTime} initValue={data.startTime} onChange={set('startTime')} />
                <EditInput type="time" label={t.protocol.properties.endTime} initValue={data.endTime} onChange={set('endTime')} />
              </div>
              <div className="mt-3 bg-white rounded-sm shadow-sm p-3">
                <div className="flex flex-col gap-3">
                  <Teilnehmer label={t.protocol.properties.teilnehmer} value={data.teilnehmer} onChange={set('teilnehmer')} />
                  <Teilnehmer
                    label={t.protocol.properties.teilnehmerZusaetzlich}
                    value={data.teilnehmerZusaetzlich}
                    onChange={set('teilnehmerZusaetzlich')}
                  />
                  <Mitarbeiter
                    label={t.protocol.properties.teilnehmerMitarbeiter}
                    protocolEntry={data}
                    value={data.teilnehmerMitarbeiter}
                    onChange={set('teilnehmerMitarbeiter')}
                  />
                </div>
                <Textarea highlightLabel label={t.protocol.properties.notes} value={data.notes} onChange={set('notes')} />
              </div>
              <div className="mt-3 p-3 bg-white rounded-sm shadow-sm">
                <Themen label={t.protocol.properties.themen} value={data.themen} onChange={set('themen')} />
              </div>
              <div className="mt-3 p-3 bg-white rounded-sm shadow-sm">
                <Attachments protocolUUID={uuid} label={t.protocol.properties.attachments} files={data.attachments} onChange={set('attachments')} />
              </div>
              <SaveBar small onSave={save} label={t.protocol.view.save} />
            </div>
          )}
        </div>
      </Container>
    </FullHeightWrapper>
  )
}

export default Entry

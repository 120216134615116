import Upsert from './Upsert'
import { Contact } from '../../../../types/Contact'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import Status from '../../../UI/Status/Status'

type Props = { data: Contact }

const Entry: React.FC<Props> = ({ data }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const isMail = (prop: string, mail: string) => {
    if (!prop || !mail || prop !== 'mail' || mail.trim() === '') return false
    return true
  }
  const isWebsite = (prop: string, website: string) => {
    if (!prop || !website || prop !== 'website' || website.trim() === '') return false
    return true
  }

  const getPropElement = (prop: string, value: string) => {
    if (isMail(prop, value)) {
      return (
        <a href={`mailto:${value}`} className="truncate underline block px-2">
          {value || '-'}
        </a>
      )
    } else if (isWebsite(prop, value)) {
      return (
        <a href={value} target="_blank" rel="noreferrer" className="truncate underline block px-2">
          {value || '-'}
        </a>
      )
    }
    return <div className="truncate px-2">{value || '-'}</div>
  }

  const getHeaderElement = (prop: string) => {
    return <div className="text-sm text-gray font-bold px-2">{t.contact.properties[prop]}</div>
  }

  return (
    <div className="flex w-full flex-col items-center gap-y-2 rounded-sm shadow-sm bg-white p-3">
      <div className="flex w-full flex-wrap gap-y-2">
        {['firma', 'kuerzel', 'gewerk', 'adresse', 'telefon', 'mobil', 'mail', 'website'].map((p, i) => (
          <div className="w-3/12" key={i + p}>
            {getHeaderElement(p)}
            {getPropElement(p, data[p])}
          </div>
        ))}
        <div className="w-3/12">
          {getHeaderElement('projectUUIDList')}
          <div className="truncate px-2">
            {Array.isArray(data.projects) && data.projects.length > 0 ? data.projects.map((p) => p.name).join(', ') : '-'}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        {getHeaderElement('ansprechpartnerList')}
        {Array.isArray(data.ansprechpartnerList) && data.ansprechpartnerList.length > 0 ? (
          <div>
            <div className="flex">
              <div className="w-2/12">
                <div className="text-sm text-gray px-2">{t.contact.properties.name}</div>
              </div>
              {['position', 'telefon', 'mobil', 'mail'].map((p, i) => (
                <div className="w-2/12" key={i + p}>
                  <div className="text-sm text-gray px-2">{t.contact.properties[p]}</div>
                </div>
              ))}
              <div className="w-2/12"></div>
            </div>
            {data.ansprechpartnerList.map((a, i) => (
              <div className="flex" key={i}>
                <div className="w-2/12 px-2">
                  <div>{`${a.vorname || ''} ${a.nachname || ''}`}</div>
                </div>
                {['position', 'telefon', 'mobil', 'mail'].map((p) => (
                  <div className="w-2/12">{getPropElement(p, a[p])}</div>
                ))}
                <div className="w-2/12"></div>
              </div>
            ))}
          </div>
        ) : (
          '-'
        )}
      </div>
      <div className="flex w-full">
        <div className="w-full">
          <div className="flex items-center">
            {getHeaderElement('bemerkung')}
            {!!data.bewertung ? <Status status={data.bewertung} /> : '-'}
          </div>
          <div className="whitespace-pre-wrap px-2">{data.bemerkung || '-'}</div>
        </div>
        <div className="w-fit flex gap-3 justify-end items-end">
          {data.isSensitive && (
            <Tooltip text={t.contact.properties.isSensitive}>
              <LockClosedIcon className="w-5 fill-gray" />
            </Tooltip>
          )}
          <Upsert initData={data} />
        </div>
      </div>
    </div>
  )
}

export default Entry

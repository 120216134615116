import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import ModalDynamicInput from '../../../UI/ModalDynamicInput/ModalDynamicInput'

type Props = {
  value: string[]
  onChange: (value: string[]) => void
  disableSearch?: boolean
}

const AreaManager: React.FC<Props> = ({ value, onChange, disableSearch }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return <ModalDynamicInput disableSearch={disableSearch} value={value} onChange={onChange} label={t.project.properties.areas.title} />
}

export default AreaManager

import ContextMenu, { ContextOption } from './ContextMenu/ContextMenu'

export const RowWrapper: React.FC<{
  contextOptions?: ContextOption[]
  children: React.ReactNode
  className?: string
}> = ({ children, className, contextOptions }) => {
  if (contextOptions) {
    return (
      <ContextMenu options={contextOptions} className={`flex p-2 ${className}`}>
        {children}
      </ContextMenu>
    )
  }
  return <div className={`flex p-2 ${className}`}>{children}</div>
}

export const ItemWrapper: React.FC<{
  children: React.ReactNode
  contextOptions?: ContextOption[]
  className?: string
  Icon?: React.ReactNode
  onClick?: () => void
  onDoubleClick?: () => void
  small?: boolean
}> = ({ children, className, onClick, onDoubleClick, Icon, contextOptions, small = true }) => {
  const classNameContent = `shrink-0 flex items-center gap-1 p-0 ${small ? 'w-2/12' : 'w-6/12'} ${className} ${!!onClick && 'cursor-pointer'}`
  const content = (
    <>
      {!!Icon && <div className="shrink-0">{Icon}</div>}
      <div className="text-ellipsis whitespace-nowrap overflow-hidden">{children}</div>
    </>
  )

  if (!!contextOptions) {
  } else {
    return (
      <div className={classNameContent} onClick={onClick} onDoubleClick={onDoubleClick}>
        {content}
      </div>
    )
  }
  return (
    <ContextMenu options={contextOptions} className={classNameContent} onClick={onClick} onDoubleClick={onDoubleClick}>
      {content}
    </ContextMenu>
  )
}

import { Project as ProjectType } from '../../../../types/Project'
import { TimetrackerFilter, TimetrackerSummary } from '../../../../types/Timetracker'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import FilterAndExport from '../Project/FilterAndExport/FilterAndExport'
import Button from '../../../UI/Button/Button'
import Summary from '../Project/Summary/Summary'
import { useEffect, useState } from 'react'
import useTimetracker from '../../../hooks/useContext/useTimetracker'
import _ from 'lodash'

type Props = {
  project: ProjectType
  filterConfig: TimetrackerFilter
}

const Project: React.FC<Props> = ({ project, filterConfig }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [summary, setSummary] = useState<TimetrackerSummary | false>()
  const { getSummary } = useTimetracker()
  const [currentFilter, setCurrentFilter] = useState<TimetrackerFilter>(filterConfig)

  useEffect(() => {
    const load = async () => {
      const summary = await getSummary({ ...filterConfig, projectUUID: project.uuid })
      setSummary(summary)
      setCurrentFilter(filterConfig)
    }

    if (!summary || !_.isEqual(currentFilter, filterConfig)) {
      load()
    }
  }, [summary, getSummary, filterConfig, currentFilter, project.uuid])

  const getProjectViewLink = () => {
    return `/dashboard/timetracking/project/${project.uuid}`
  }

  if (!summary || summary.totalHours === 0) return null
  return (
    <div className="rounded-sm shadow-sm bg-white p-3">
      <div className="flex justify-between border-b pb-1 border-blue">
        <a href={getProjectViewLink()} className="font-bold text-lg hover:underline">
          {project.name}
        </a>
        <div className="flex gap-3 items-center">
          <Button small href={getProjectViewLink()} text={t.timetracking.summary.openProject} />
          <FilterAndExport project={project} />
        </div>
      </div>
      <Summary initSummary={summary} project={project} filter={{ ...filterConfig, projectUUID: project.uuid }} />
    </div>
  )
}

export default Project

import { getIcon } from 'material-file-icons'
import { Folder } from 'lucide-react'

type Props = {
  filename: string
  isFolder?: boolean
}

const GetFileIcon: React.FC<Props> = ({ filename, isFolder = false }) => {
  return (
    <div className="w-5">
      {!!isFolder && <Folder className="w-5 h-5 text-blue-500" />}
      {!isFolder && <div dangerouslySetInnerHTML={{ __html: getIcon(filename).svg }} />}
    </div>
  )
}

export default GetFileIcon

import { useSelector } from 'react-redux'
import Datepicker from 'react-tailwindcss-datepicker'
import props from '../../../redux/props'
import { getCurrentWeekSpan } from '../../../utility'
import styled from 'styled-components'

export type DateRange = {
  startDate: Date | null
  endDate: Date | null
}

type Props = {
  id?: string
  label?: string
  className?: string
  invert?: boolean
  required?: boolean
  value: DateRange
  onChange: (value: DateRange) => void
  minDate?: boolean
  noLabel?: boolean
  enableWeekSelection?: boolean
}

const DateRangePicker: React.FC<Props> = ({
  id,
  required,
  label,
  className = '',
  invert = false,
  value,
  onChange,
  minDate = false,
  noLabel = false,
  enableWeekSelection = false,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalChange = (value) => {
    if (enableWeekSelection) {
      onChange(getCurrentWeekSpan(value.startDate))
    } else {
      onChange(value)
    }
  }

  const Wrapper = styled.div<{ enableWeekSelection: boolean }>`
    ${({ enableWeekSelection }) =>
      enableWeekSelection &&
      `
    > div > button {
      display: none;
    }
  `}
  `

  return (
    <Wrapper enableWeekSelection={enableWeekSelection} className={`w-full ${className}`}>
      {!noLabel && (
        <label htmlFor={id} className={invert ? 'text-white text-sm' : 'text-gray text-sm'}>
          {label || (!enableWeekSelection ? t.UI.dateRangePicker.label : t.UI.dateRangePicker.labelSingleSelect)}
        </label>
      )}
      <Datepicker
        minDate={minDate ? new Date() : undefined}
        primaryColor={'yellow'}
        value={value}
        onChange={(newValue) => internalChange(newValue)}
        inputClassName="border rounded-sm border-gold placeholder-gray w-full py-1 px-1 bg-white text-black focus:outline-none focus:shadow-outline"
        displayFormat="DD.MM.YYYY"
        i18n="de"
        required={required}
        startWeekOn="mon"
        asSingle={enableWeekSelection}
        useRange={!enableWeekSelection}
      />
    </Wrapper>
  )
}

export default DateRangePicker

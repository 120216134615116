import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../redux/props'
import { useEffect, useState } from 'react'
import { Member } from '../../../types/Member'
import { useSelector } from 'react-redux'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'

type Response = Member

const useOwnMember = (): Response => {
  const memberFeature = crud.use(props.MEMBER)
  const [member, setMember] = useState<Member>(null)
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  useEffect(() => {
    const fetchMember = async () => {
      const member = await memberFeature.get({ uuid: accountLink.assignedToUUID })
      if (member) {
        setMember(member)
      }
    }
    if (!member && accountLink) {
      fetchMember()
    }
  }, [member, memberFeature, accountLink])

  return member
}

export default useOwnMember
